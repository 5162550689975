import React from 'react';

export interface SVGProps extends React.SVGProps<SVGSVGElement> {}

export const CheckboxIcon = (props: SVGProps) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="81"
    height="81"
    viewBox="0 0 81 81"
    fill="none"
    {...props}
  >
    <rect
      x="3.29404"
      y="3.29404"
      width="74.4118"
      height="74.4118"
      rx="17.4558"
      fill="#05E300"
      stroke="#04D900"
      strokeWidth="6.03339"
    />
    <path
      d="M24.4111 38.7259L37.3872 50.3967C37.5615 50.5534 37.8275 50.5481 37.9954 50.3845L60.4728 28.4835"
      stroke="white"
      strokeWidth="8.01099"
      strokeLinecap="round"
    />
  </svg>
);
