import React from 'react';
import { SVGProps } from './ArrowRight';

export const PlayIcon = (props: SVGProps) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="30"
    height="30"
    viewBox="0 0 30 30"
    fill="none"
    {...props}
  >
    <path
      d="M29.1124 14.8882C29.1124 22.743 22.7449 29.1105 14.8902 29.1105C7.03548 29.1105 0.667969 22.743 0.667969 14.8882C0.667969 7.03352 7.03548 0.666016 14.8902 0.666016C22.7449 0.666016 29.1124 7.03352 29.1124 14.8882ZM12.7402 9.72048C12.4692 9.52695 12.1128 9.50107 11.8168 9.65343C11.5207 9.80579 11.3346 10.1108 11.3346 10.4438V19.3327C11.3346 19.6657 11.5207 19.9707 11.8168 20.1231C12.1128 20.2754 12.4692 20.2495 12.7402 20.056L18.9624 15.6116C19.196 15.4447 19.3346 15.1753 19.3346 14.8882C19.3346 14.6012 19.196 14.3318 18.9624 14.1649L12.7402 9.72048Z"
      fill="white"
    />
  </svg>
);
