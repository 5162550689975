import React from 'react';

export interface SVGProps extends React.SVGProps<SVGSVGElement> {}

export const ChevronDownIcon = (props: SVGProps) => {
  const { color = '#3E3E40', ...otherProps } = props;

  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="17"
      height="16"
      viewBox="0 0 17 16"
      fill="none"
      {...otherProps}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M1.74935 4.64645C1.95682 4.45118 2.29318 4.45118 2.50065 4.64645L8.5 10.2929L14.4994 4.64645C14.7068 4.45118 15.0432 4.45118 15.2507 4.64645C15.4581 4.84171 15.4581 5.15829 15.2507 5.35355L8.87565 11.3536C8.66819 11.5488 8.33182 11.5488 8.12435 11.3536L1.74935 5.35355C1.54188 5.15829 1.54188 4.84171 1.74935 4.64645Z"
        fill={color}
        stroke={color}
        strokeWidth="0.833333"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};
