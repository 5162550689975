import React, { ReactNode } from 'react';
import { CubeIcon } from '../../../components/Icons/Cube';
import clsx from 'clsx';

export const CellImg = ({
  children,
  title,
  style,
  has3D = '',
  onClick,
  selected,
  setIsModalOpen,
  setChecked3D,
}: {
  children?: ReactNode;
  title: ReactNode;
  style: any;
  has3D?: string;
  onClick?: (e: React.MouseEvent<HTMLElement>) => void;
  selected: boolean;
  setIsModalOpen: (value: string) => void;
  setChecked3D: (value: string) => void;
}) => (
  <div>
    <div
      className={clsx(
        'bg-white rounded-2xl w-full aspect-square mb-[10px] relative cursor-pointer transition-all border lg:border-[5px] border-[3px] border-[transparent]',
        selected && '!border-[#FF4545]',
      )}
      style={style}
      onClick={onClick}
    />
    <div className="flex gap-2 items-center justify-center">
      <div className="font-Istok font-normal lg:text-base text-sm leading-5">{title}</div>
      {has3D && (
        <div
          className="flex items-center lg:gap-2 gap-[6px] text-[#0004] cursor-pointer"
          onClick={(e) => {
            const top = e.currentTarget.getBoundingClientRect().top;
            if (top) {
              sessionStorage.setItem('modalTop', (top > 150 ? top - 150 : top).toString());
            }
            setIsModalOpen(has3D);
            setChecked3D(has3D);
          }}
        >
          |
          <div className="lg:scale-100 scale-80">
            <CubeIcon />
          </div>
        </div>
      )}
    </div>
    {children}
  </div>
);
