import clsx from 'clsx';
import React, { useState } from 'react';
import { CrossRedIcon } from '../Icons/Cross';
import { DoneIcon } from '../Icons/Done';
import MaskedInput from 'react-text-mask';
import { phoneMask } from '../../utils/phoneMask';
import { ErrorMessage } from 'formik';

interface Props extends React.InputHTMLAttributes<HTMLInputElement> {
  onClear?: () => void;
  ok?: boolean;
  animatedPlaceholder?: boolean;
  field?: any;
}

export const Input = ({ ok, animatedPlaceholder, className, onClear, ...otherProps }: Props) => {
  const [focused, setFocused] = useState(false);
  const form: any = otherProps.form;
  const field: any = otherProps.field;

  return (
    <div className={clsx('relative', className)}>
      {otherProps.type !== 'tel' && (
        <input
          className={clsx(
            'px-4 py-2 border border-[#3E3E40] font-Istok font-bold text-base text-[#3E3E40] rounded-lg',
            focused ? 'placeholder-up' : '',
            onClear && 'pr-10',
            className,
          )}
          onFocus={() => setFocused(true)}
          onBlur={() => setFocused(false)}
          {...otherProps}
          placeholder={animatedPlaceholder ? '' : otherProps.placeholder}
        />
      )}

      {otherProps.type === 'tel' && (
        <>
          <MaskedInput
            {...otherProps}
            onChange={(e) => {
              const target = e.target as HTMLInputElement;
              const value = target?.value;
              if ((value[3] === ' ' || !value?.trim()) && value[1] !== '7') {
                e.target.value = '+7(   )   -  -  ';
                e.target.setSelectionRange(3, 3);
              } else if (value?.[3] && value[3] !== '9' && value[4] === ' ' && value[3] !== ' ') {
                const digit = `${value[3]}`;
                const newValue = `${value}`.split('');
                newValue[3] = '9';
                newValue[4] = digit;
                e.target.value = newValue.join('');
                e.target.setSelectionRange(5, 5);
              } else if (value?.[1] && value[1] !== '7' && value[3] === ' ') {
                const digit = `${value[1]}`;
                const newValue = `${value}`.split('');
                newValue[1] = '7';
                if (digit === '9') {
                  newValue[3] = digit;
                  e.target.value = newValue.join('');
                  e.target.setSelectionRange(4, 4);
                } else if (digit !== '7') {
                  newValue[3] = '9';
                  newValue[4] = digit;
                  e.target.value = newValue.join('');
                  e.target.setSelectionRange(5, 5);
                }
              }
              if (otherProps.onChange) otherProps.onChange(e);
            }}
            mask={phoneMask}
            placeholderChar={'\u2000'}
            onClick={(e) => {
              const target = e.target as HTMLInputElement;
              const value = target?.value;
              if ((value[3] === ' ' || !value?.trim()) && value[1] !== '7') {
                e.currentTarget.value = '+7(   )   -  -  ';
                e.currentTarget.setSelectionRange(3, 3);
              }
            }}
            onFocus={(e) => {
              const target = e.target as HTMLInputElement;
              const value = target?.value;
              if ((value[3] === ' ' || !value?.trim()) && value[1] !== '7') {
                e.target.value = '+7(   )   -  -  ';
                e.target.setSelectionRange(3, 3);
              }
            }}
            className={clsx(
              'px-4 py-2 border border-[#3E3E40] font-Istok font-bold text-base text-[#3E3E40] rounded-lg',
              focused ? 'placeholder-up' : '',
              onClear && 'pr-10',
              className,
            )}
            placeholder={animatedPlaceholder ? '' : otherProps.placeholder}
          />
          {form?.errors?.[field?.name] && form?.touched[field?.name] && (
            <>
              <div className="error">{form.errors[field.name]}</div>
              <ErrorMessage name={field?.name} component="div" className="error" />
            </>
          )}
        </>
      )}

      {animatedPlaceholder && (
        <span
          className={clsx(
            'absolute left-4 text-[#9B9DA1] transition-all duration-300 font-normal',
            focused && otherProps.value
              ? 'text-xs top-1 left-0'
              : clsx(
                  'text-base top-1/2 transform -translate-y-1/2 left-4',
                  otherProps.value && 'hidden',
                ),
          )}
        >
          {otherProps.placeholder}
        </span>
      )}
      {onClear && !ok && (
        <CrossRedIcon
          className="absolute top-1/2 right-4 transform -translate-y-1/2 cursor-pointer"
          onClick={() => onClear()}
        />
      )}
      {ok && (
        <DoneIcon className="absolute top-1/2 right-4 transform -translate-y-1/2 cursor-pointer" />
      )}
    </div>
  );
};
