import React, { useState } from 'react';
import { QuestionIcon } from '../Icons/Question';
import { formatNumber } from '../../utils/helpers';
import Button from '../Button';
import { useDispatch, useSelector } from 'react-redux';
import { RootState, deleteOption, setName } from '../../redux/store';
import { ModalDesc } from './ModalDesc';

interface Props {
  data: any;
  onClick?: any;
  type: 'windows' | 'balconies';
  index: number;
  i: number;
  prices?: any;
}

export const Item = ({ data, onClick, type, index, i, prices }: Props) => {
  const dispatch = useDispatch();
  const names = useSelector((state: RootState) => state.global.names);
  const profilesInfo = useSelector((state: RootState) => state.global.profilesInfo);
  const [isOpen, setIsOpen] = useState(false);
  const option = data.values?.[data.selected];

  const handleDelete = () => {
    dispatch(deleteOption({ type, index, i }));
    if (data.slug in names[type]) {
      if (data.slug !== 'lamination') {
        dispatch(setName({ type, key: data.slug, index, value: '' }));
      } else {
        dispatch(setName({ type, key: 'lamination_external', index, value: '' }));
        dispatch(setName({ type, key: 'lamination_interior', index, value: '' }));
      }
    }
  };

  const interiorImageURL = names?.[type]?.['lamination_interior']?.[index]
    ? profilesInfo?.lamination?.values?.find(
        (el: any) => el.name === names?.[type]?.['lamination_interior']?.[index],
      )?.small_image_path
    : '';

  const exteriorImageURL = names?.[type]?.['lamination_external']?.[index]
    ? profilesInfo?.lamination?.values?.find(
        (el: any) => el.name === names?.[type]?.['lamination_external']?.[index],
      )?.small_image_path
    : '';

  const twoLaminations = !!interiorImageURL && !!exteriorImageURL;

  return option && option?.price ? (
    <>
      <div
        className={'flex items-center w-full mb-4 font-normal'}
        style={data?.sort !== undefined ? { order: data.sort } : {}}
      >
        {data.slug === 'lamination' ? (
          <div
            className="lg:w-[60px] w-[48px] aspect-square rounded-lg mr-3 bg-no-repeat bg-center"
            style={{
              backgroundImage: twoLaminations
                ? `url(${interiorImageURL}), url(${exteriorImageURL})`
                : `url(${interiorImageURL || exteriorImageURL})`,
              backgroundSize: twoLaminations ? '50% 100%, 50% 100%' : '100% 100%',
              backgroundPosition: twoLaminations ? 'left, right' : 'center',
            }}
          />
        ) : (
          <div
            className="lg:w-[60px] w-[48px] aspect-square rounded-lg mr-3 bg-contain bg-no-repeat bg-center"
            style={{ backgroundImage: `url(${option?.image})` }}
          />
        )}
        <div className="w-[55%] lg:text-base text-sm lg:mr-0 mr-1">
          <div className="flex lg:gap-2 gap-[6px] items-center lg:font-normal font-bold justify-between">
            <div>
              {data.name?.trim() || ''}
              {data.slug !== 'lamination' &&
              option.name &&
              option.name?.trim() !== data.name?.trim()
                ? `: ${option.name}`
                : ''}
              {data.slug === 'lamination' && (
                <>
                  :{' '}
                  {names?.[type]?.['lamination_interior']?.[index] &&
                  names?.[type]?.['lamination_external']?.[index]
                    ? `${names?.[type]?.['lamination_interior']?.[index]}/${names?.[type]?.['lamination_external']?.[index]}`
                    : names?.[type]?.['lamination_interior']?.[index] ||
                      names?.[type]?.['lamination_external']?.[index]}
                </>
              )}
            </div>
            {data.description && (
              <QuestionIcon
                onClick={(e) => {
                  const top = e?.currentTarget?.getBoundingClientRect().top;
                  if (top) {
                    sessionStorage.setItem('modalTop', (top > 100 ? top - 100 : top).toString());
                  }
                  setIsOpen(true);
                }}
                role="button"
                tabIndex={0}
              />
            )}
          </div>

          <div className="lg:hidden block text-[13px]">
            {data.slug === 'lamination'
              ? `${prices?.lamination_interior?.price || prices?.lamination_external?.price || '-'}`
              : formatNumber(option?.price)}{' '}
            ₽
          </div>
          {data.deletable && (
            <button className="text-[#FF4545] text-sm" onClick={() => handleDelete()}>
              Удалить опцию
            </button>
          )}
        </div>
        <div className="mx-2 lg:block hidden whitespace-nowrap">
          {data.slug === 'lamination'
            ? `${prices?.lamination_interior?.price || prices?.lamination_external?.price || '-'}`
            : formatNumber(option?.price)}{' '}
          ₽
        </div>
        {data.editable || data.slug === 'lamination' ? (
          <Button variant="outline" className="ml-auto" onClick={onClick}>
            Изменить
          </Button>
        ) : (
          <div />
        )}
      </div>
      {isOpen && <ModalDesc setIsOpen={setIsOpen} data={data} />}
    </>
  ) : null;
};
