import clsx from 'clsx';
import React from 'react';

interface Props extends React.HTMLAttributes<HTMLDivElement> {}

export const Block = (props: Props) => {
  const { children, className, ...otherProps } = props;

  return (
    <div className={clsx('rounded-[20px] bg-[#F4F4F4] lg:p-6 p-4', className)} {...otherProps}>
      {children}
    </div>
  );
};
