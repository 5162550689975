import clsx from 'clsx';
import React, { ReactNode } from 'react';

export const Cell = ({
  active,
  className,
  children,
  title,
  style,
}: {
  children?: ReactNode;
  className?: string;
  title: ReactNode;
  style: any;
  active?: boolean;
}) => (
  <div>
    <div
      className={clsx(
        'bg-white rounded-2xl w-full aspect-square mb-[10px] relative transition-all',
        className,
      )}
      style={{ ...style, border: `2px solid ${active ? '#FF4545' : 'transparent'}` }}
    />
    <div className="font-Istok font-bold text-base">{title}</div>
    {children}
  </div>
);
