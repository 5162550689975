import React, { useCallback, useEffect, useState } from 'react';
import { Block } from '../../Block';
import Button from '../../Button';
import { Cell } from '../Cell';
import { Checkbox } from '../../Checkbox';
import { useDispatch } from 'react-redux';
import { setWindows as setWindowsRedux } from '../../../redux/store';
import 'swiper/css';
import { Swiper, SwiperSlide } from 'swiper/react';
import { FreeMode, Mousewheel } from 'swiper/modules';

interface Props {
  data: any[];
}

export const SelectorWindows = ({ data = [] }: Props) => {
  const dispatch = useDispatch();
  const [windows, setWindows] = useState<boolean[]>(Array(data.length).fill(false));
  const [qWindows, setQWindows] = useState(Array(data.length).fill(0));

  const showEl = useCallback(
    (el: any, i: number) => (
      <div className="relative lg:w-auto w-[180px]" key={JSON.stringify(el) + i}>
        <Cell
          active={qWindows[i] > 0}
          title={el.title}
          className="bg-contain bg-[50%_60%] bg-white bg-no-repeat"
          style={{ backgroundImage: `url(${el.image_path})` }}
        />
        {qWindows[i] ? (
          <div className="lg:top-4 top-3 left-1/2 -translate-x-1/2 absolute select-none flex items-center lg:gap-3 gap-2 font-bold font-Istok">
            <Button
              variant="minus"
              onClick={() => {
                if (qWindows[i] > 0) {
                  setQWindows((p) => p.map((el, index) => (index === i ? el - 1 : el)));
                }
                if (qWindows[i] === 1) {
                  setWindows((p) => p.map((el, index) => (index === i ? false : el)));
                }
              }}
            />
            {qWindows[i]}
            <Button
              variant="plus"
              onClick={() => setQWindows((p) => p.map((el, idx) => (idx === i ? el + 1 : el)))}
            />
          </div>
        ) : null}
        <Checkbox
          onChange={(checked) => {
            setQWindows((p) => p.map((el, idx) => (idx === i ? (checked ? 1 : 0) : el)));
            setWindows((prev) => prev.map((el, index) => (index === i ? !el : el)));
          }}
          checked={windows[i]}
          className="lg:top-4 top-3 lg:right-4 right-3 absolute"
        />
      </div>
    ),
    [qWindows, windows],
  );

  useEffect(() => {
    const newData: any = {};
    qWindows.forEach((el, i) => {
      if (el > 0) {
        newData[data[i].id] = el;
      }
    });
    dispatch(setWindowsRedux(newData));
  }, [qWindows]);

  useEffect(() => {
    setWindows(Array(data.length).fill(false));
    setQWindows(Array(data.length).fill(0));
  }, []);

  if (!data?.length) return null;

  return (
    <Block>
      <div className="lg:text-[32px] text-[19px] lg:mb-6 mb-3">
        Выберите окна, которые планируете менять
      </div>
      <div className="lg:grid hidden grid-cols-4 gap-9">{data?.map((el, i) => showEl(el, i))}</div>
      <div className="lg:hidden block">
        <Swiper
          slidesPerView={'auto'}
          className={'cursor-grab active:cursor-grabbing select-none'}
          modules={[FreeMode, Mousewheel]}
          mousewheel={{ forceToAxis: true }}
          freeMode={{ enabled: true, sticky: false }}
        >
          {data?.map((el, i) => (
            <SwiperSlide className={`!w-auto mr-4 last:mr-0`} key={JSON.stringify(el)}>
              {showEl(el, i)}
            </SwiperSlide>
          ))}
        </Swiper>
      </div>
    </Block>
  );
};
