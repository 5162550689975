import React from 'react';
import { SVGProps } from './ArrowRight';

export const CubeIcon = (props: SVGProps) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="25"
    height="24"
    viewBox="0 0 25 24"
    fill="none"
    {...props}
  >
    <path
      d="M21.5364 6.2017L13.2864 1.68764C13.066 1.56584 12.8182 1.50195 12.5664 1.50195C12.3146 1.50195 12.0668 1.56584 11.8464 1.68764L3.59641 6.20358C3.3608 6.33249 3.16413 6.52229 3.02692 6.75316C2.88972 6.98404 2.81702 7.24751 2.81641 7.51608V16.4823C2.81702 16.7509 2.88972 17.0144 3.02692 17.2452C3.16413 17.4761 3.3608 17.6659 3.59641 17.7948L11.8464 22.3108C12.0668 22.4326 12.3146 22.4965 12.5664 22.4965C12.8182 22.4965 13.066 22.4326 13.2864 22.3108L21.5364 17.7948C21.772 17.6659 21.9687 17.4761 22.1059 17.2452C22.2431 17.0144 22.3158 16.7509 22.3164 16.4823V7.51701C22.3163 7.24797 22.2438 6.98391 22.1066 6.7525C21.9694 6.52108 21.7724 6.33084 21.5364 6.2017ZM12.5664 3.00014L20.0983 7.12514L12.5664 11.2501L5.03453 7.12514L12.5664 3.00014ZM4.31641 8.43764L11.8164 12.542V20.5848L4.31641 16.4833V8.43764ZM13.3164 20.5848V12.5458L20.8164 8.43764V16.4795L13.3164 20.5848Z"
      fill="#FF4545"
    />
  </svg>
);
