import clsx from 'clsx';
import React, { useState } from 'react';
import { QuestionIcon } from '../Icons/Question';
import Button from '../Button';
import { ModalDesc } from './ModalDesc';

export const Option = ({
  data,
  full,
  restore,
  prices,
}: {
  data: any;
  full?: boolean;
  restore?: any;
  prices?: any;
}) => {
  const [isOpen, setIsOpen] = useState(false);
  const value0 = data?.values?.[0];
  const name =
    value0?.name && value0.name.trim() !== data.name.trim()
      ? `${data.name}: ${value0.name}`
      : data.name;

  if (!value0?.price) return null;

  return (
    <>
      <div
        className={clsx('flex', !full && 'flex-col', full && 'items-center')}
        style={data?.sort !== undefined ? { order: data.sort } : {}}
      >
        <div
          className="lg:w-[60px] w-[48px] aspect-square rounded-lg mr-3 bg-contain bg-no-repeat"
          style={{ backgroundImage: `url(${value0?.image || data.image})` }}
        />
        <div
          className={clsx(
            'font-normal mt-[6px]',
            full && 'inline-flex items-center gap-[6px] lg:w-1/2 w-full',
            full ? 'lg:text-base text-xs' : 'lg:text-sm text-xs lg:w-[60px] w-[48px] ',
          )}
        >
          <div>
            {full ? name : data?.name}
            {full &&
              (data?.slug === 'lamination' ? (
                <div className="font-normal text-xs mr-1 lg:hidden block">
                  {prices?.[data?.slug]?.price || value0?.price}&nbsp;₽{' '}
                </div>
              ) : (
                <div className="font-normal text-xs mr-1 lg:hidden block">
                  {prices?.[data?.slug]?.price || value0?.price}&nbsp;₽{' '}
                </div>
              ))}
          </div>{' '}
          {full && data?.image && data?.description && (
            <QuestionIcon
              className="mr-4"
              color="white"
              onClick={(e) => {
                const top = e?.currentTarget?.getBoundingClientRect().top;
                if (top) {
                  sessionStorage.setItem('modalTop', (top > 100 ? top - 100 : top).toString());
                }
                setIsOpen(true);
              }}
              role="button"
              tabIndex={0}
            />
          )}
        </div>
        {full &&
          (data?.slug === 'lamination' ? (
            <div className="font-normal text-base mt-[6px] mr-1 lg:block hidden">
              {prices?.[data?.slug]?.price || value0?.price}&nbsp;₽{' '}
            </div>
          ) : (
            <div className="font-normal text-base mt-[6px] mr-1 lg:block hidden">
              {prices?.[data?.slug]?.price || value0?.price}&nbsp;₽{' '}
            </div>
          ))}
        {full && (
          <Button variant="white" className="ml-auto lg:!px-5 !px-3" onClick={restore}>
            Добавить
          </Button>
        )}
      </div>
      {isOpen && <ModalDesc setIsOpen={setIsOpen} data={data} />}
    </>
  );
};
