import { ErrorMessage, Field, FieldAttributes } from 'formik';
import React from 'react';
import { Input } from '../Input';

export const FormItem = ({
  name,
  placeholder,
  error,
  disabled = false,
}: {
  name: string;
  placeholder: string;
  error: any;
  disabled?: boolean;
}) => (
  <div>
    <Field type="text" name={name}>
      {({ field }: { field: FieldAttributes<any> }) => (
        <Input
          {...field}
          placeholder={placeholder}
          className="w-full font-normal"
          style={error ? { border: '2px solid #F00' } : {}}
          disabled={disabled}
        />
      )}
    </Field>
    <ErrorMessage
      name={name}
      component="div"
      className="ml-4 pt-1 text-[#F00] font-Istok text-xs"
    />
  </div>
);
