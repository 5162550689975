import React from 'react';
import { Modal } from '../Modal';

export const ModalDesc = ({ data, setIsOpen }: { data: any; setIsOpen: any }) => (
  <Modal
    className="md:w-1/3 sm:w-1/2 w-full relative !bg-[#F4F4F4] overflow-auto flex flex-col gap-3 text-black"
    onClose={() => setIsOpen(false)}
  >
    <h2 className="font-semibold text-[20px]">{data.name}</h2>
    <div
      className="w-full aspect-[1] h-fit bg-no-repeat bg-contain"
      style={{ backgroundImage: `url(${data.image})` }}
    />
    <p className="text-base font-normal" dangerouslySetInnerHTML={{ __html: data.description }} />
  </Modal>
);
