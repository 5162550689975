import './index.css';

import React from 'react';
import ReactDOM from 'react-dom/client';

import MyApp from './App';

export const rootElement = document.querySelector('#root');

if (rootElement) {
  const root = ReactDOM.createRoot(rootElement);
  root.render(<MyApp />);
}
