import clsx from 'clsx';
import React from 'react';

interface Props {
  checked: boolean;
  hideText?: boolean;
  onChange: (e: boolean) => void;
  disabled?: boolean;
}

export const Selector = ({ checked, hideText, onChange, disabled = false }: Props) => (
  <div
    className={clsx(
      'relative w-[53px] h-[26px] p-1 rounded-[32px] transition-all flex items-center select-none text-sm font-normal',
      !disabled && 'cursor-pointer',
    )}
    style={{ background: checked ? '#FF4545' : '#CDCDCD' }}
    onClick={() => !disabled && onChange(!checked)}
  >
    <div
      className={clsx(
        'w-[18px] h-[18px] bg-white transition-all absolute top-1',
        checked ? 'right-1' : 'right-[31px]',
      )}
      style={{ borderRadius: '50%', background: checked ? '#fff' : '#3E3E40' }}
    />
    {!hideText && (
      <div className={checked ? 'mr-auto ml-[2px] text-white' : 'ml-auto mr-[2px] text-[##3E3E40]'}>
        {checked ? 'ДА' : 'НЕТ'}
      </div>
    )}
  </div>
);
