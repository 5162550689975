import React from 'react';
import { useNavigate } from 'react-router-dom';

const Page404 = () => {
  const navigate = useNavigate();

  return (
    <div className="Page404-container h-screen flex flex-col justify-center">
      <p className="Page404-sadSmile" role="img" aria-label="sad smile">
        ☹️
      </p>
      <h1>Ошибка 404</h1>
      <p>Страница не найдена!</p>
      <p className="Page404-text--small text-center">
        Похоже страница, которую вы ищете,
        <br />
        не существует.
      </p>
      <button
        type="button"
        className="border px-4 py-3 rounded-2xl font-semibold bg-[lightgrey]"
        onClick={() => navigate('/')}
      >
        НАЗАД
      </button>
    </div>
  );
};

export default Page404;
