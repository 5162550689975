import { handleError } from '../helpers';

import axiosInstance from '.';

export const fetchAddresses = async (address = '') => {
  try {
    if (!address) {
      return null;
    }
    const response = await axiosInstance.post('/address', { address });
    return response?.data;
  } catch (error) {
    handleError(error);
    return { error };
  }
};

export const fetchPlans = async (data) => {
  try {
    const response = await axiosInstance.post('/calculate/plans', data);
    return response?.data;
  } catch (error) {
    if (error?.message && !error.response.data) {
      handleError(error);
      return { error };
    }
    console.error('ERROR:', error);
    return error;
  }
};

export const fetchPlansDefault = async (data) => {
  try {
    const response = await axiosInstance.post('/calculate/plans/default', data);
    return response?.data;
  } catch (error) {
    if (error?.message && !error.response.data) {
      handleError(error);
      return { error };
    }
    console.error('ERROR:', error);
    return error;
  }
};

export const sendEmail = async (planId, data) => {
  try {
    const response = await axiosInstance.post(`/calculate/mail${planId ? `/${planId}` : ''}`, data);
    return response?.data;
  } catch (error) {
    if (error?.message && !error.response.data) {
      handleError(error);
      return { error };
    }
    console.error('ERROR:', error);
    return error;
  }
};

export const createOrder = async (planId, data) => {
  try {
    const response = await axiosInstance.post(
      `/calculate/order${planId ? `/${planId}` : ''}`,
      data,
    );
    return response?.data;
  } catch (error) {
    if (error?.message && !error.response.data) {
      handleError(error);
      return { error };
    }
    console.error('ERROR:', error);
    return error;
  }
};

export const getOptionsCustom = async (data) => {
  try {
    const addressSS = sessionStorage.getItem('address');
    let fias = '';
    if (addressSS) {
      const address = JSON.parse(addressSS);
      fias = `fias=${address.fias_id}`;
    }
    const response = await axiosInstance.post(`/calculate/options/custom?${fias}`, data);
    const rdata = response?.data;
    let arr = [];
    if (rdata && Array.isArray(rdata)) {
      arr = rdata.map((item) => {
        const newEl = { ...item };
        newEl.systems.sort((a, b) => {
          const sortA = sortOrder.find((item) => item.id === a.id).sort;
          const sortB = sortOrder.find((item) => item.id === b.id).sort;
          return sortB - sortA;
        });
        return newEl;
      });
      return arr;
    }
    return rdata;
  } catch (error) {
    handleError(error);
    return { error };
  }
};

export const sortOrder = [];

export const getProfilesInfo = async () => {
  try {
    const response = await axiosInstance.get(`/calculate/info`);
    const { systems } = response?.data;
    if (systems) {
      const sortData = systems.map((item) => ({ id: item.id, sort: item.sort }));
      sortOrder.push(...sortData);
    }
    return response.data;
  } catch (error) {
    return { error: error.response.data };
  }
};

export const getHouseTypes = async () => {
  try {
    const response = await axiosInstance.get('/calculate/house_types');
    return response.data;
  } catch (error) {
    return { error: error.response.data };
  }
};

export const getOptions = async (planId) => {
  try {
    const addressSS = sessionStorage.getItem('address');
    let fias = '';
    if (addressSS) {
      const address = JSON.parse(addressSS);
      fias = `fias=${address.fias_id}`;
    }
    const response = await axiosInstance.get(`/calculate/options/${planId}?${fias}`);
    const rdata = response?.data;
    let arr = [];
    if (rdata && Array.isArray(rdata)) {
      arr = rdata.map((item) => {
        const newEl = { ...item };
        newEl.systems.sort((a, b) => {
          const sortA = sortOrder.find((item) => item.id === a.id).sort;
          const sortB = sortOrder.find((item) => item.id === b.id).sort;
          return sortB - sortA;
        });
        return newEl;
      });
      return arr;
    }
    return rdata;
  } catch (error) {
    return { error: error.response.data };
  }
};

export const getPrice = async (data) => {
  if (!data) return null;
  try {
    const response = await axiosInstance.post(`/calculate/price`, data);
    return response.data;
  } catch (error) {
    return { error: error.response.data };
  }
};

export const getPrices = async (planId, data) => {
  try {
    const response = await axiosInstance.post(`/calculate/prices/${planId}`, data);
    return response.data;
  } catch (error) {
    return { error: error.response.data };
  }
};

export const getPricesCustom = async (data) => {
  try {
    const response = await axiosInstance.post(`/calculate/prices/custom`, data);
    return response.data;
  } catch (error) {
    return { error: error.response.data };
  }
};

export const sendCode = async (data) => {
  try {
    const response = await axiosInstance.post('/phone/send_code', data);
    return response?.data;
  } catch (error) {
    console.log('sendCode ERROR', error);
    handleError(error);
    return { error };
  }
};

export const confirmPhone = async (data) => {
  try {
    const response = await axiosInstance.post('/phone/confirm', data);
    return response?.data;
  } catch (error) {
    console.log('confirmPhone ERROR', error);
    return { error };
  }
};
