import 'react-toastify/dist/ReactToastify.css';

import React, { useEffect } from 'react';

import { BrowserRouter, Route, Routes } from 'react-router-dom';

import { ToastContainer } from 'react-toastify';

import { Provider } from 'react-redux';

import { QueryClient, QueryClientProvider } from 'react-query';

import routes from './AppRoutes';

import store from './redux/store';
import './styles/main.scss';
import { getToken } from './utils/httpServices/token';

export default function App() {
  const queryClient = new QueryClient();

  useEffect(() => {
    getToken();
  }, []);

  return (
    <Provider store={store}>
      <QueryClientProvider client={queryClient}>
        <BrowserRouter>
          <Routes>
            {routes.map(({ path, element }) => (
              <Route key={path} path={path} element={element} />
            ))}
          </Routes>
        </BrowserRouter>
        <ToastContainer
          limit={1}
          position="top-right"
          autoClose={2500}
          hideProgressBar={false}
          newestOnTop={false}
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable
          pauseOnHover
          theme="light"
        />
      </QueryClientProvider>
    </Provider>
  );
}
