import React, { useEffect, useMemo, useRef, useState } from 'react';

import { useNavigate, useSearchParams } from 'react-router-dom';

import { debounce } from 'lodash';

import { useDispatch } from 'react-redux';

import Dropdown from '../../components/Dropdown';
import { resetState, setPlans } from '../../redux/store';
import { DEBOUNCE_TIME_MS, MIN_LENGTH } from '../../utils/consts';
import { BuildingIcon } from '../../components/Icons/Building';
import { fetchAddresses, fetchPlans } from '../../utils/httpServices/global';

import { AddressData } from './types';
import clsx from 'clsx';
import { sendMessageFromIframe } from '../../utils/helpers';

export default function Start() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const inputRef = useRef(null);
  const searchParams = useSearchParams()?.[0];
  const extParam = searchParams?.get('ext');

  const [isLoading, setIsLoading] = useState(false);
  const [value, setValue] = useState('');
  const [options, setOptions] = useState<AddressData[] | null>(null);
  const [selected, setSelected] = useState<{ value: string; label: string } | null>(null);

  const onInput = useMemo(
    () =>
      debounce(async (address: any) => {
        setIsLoading(true);

        if (address.length >= MIN_LENGTH) {
          const res = await fetchAddresses(address);
          setOptions(res?.suggestions || null);
        } else {
          setOptions(null); // Очистка данных при коротком вводе текста
        }
        setIsLoading(false);
      }, DEBOUNCE_TIME_MS),
    [],
  );

  const handleFinish = async (edata = null) => {
    sendMessageFromIframe({ status: 'handleFinish' });
    sessionStorage.removeItem('isDefault');
    sessionStorage.removeItem('address');

    if (edata || (selected?.label === value && selected)) {
      const rdata = {
        fias_id: selected?.value || options?.[0].data.fias_id,
        address: selected?.label || options?.[0].unrestricted_value,
      };
      setIsLoading(true);

      console.log('fetchPlans');
      sendMessageFromIframe({ status: 'loading_plans' });

      const res = await fetchPlans(edata || rdata);
      sessionStorage.setItem('address', JSON.stringify(edata || rdata));

      setIsLoading(false);

      let name = res?.name || '';
      if (name && name.toLowerCase().includes('axios')) {
        name = '';
      }
      sessionStorage.setItem('name', name); // 222 case with .name

      // 200 case
      if (res?.plans) {
        console.log('res.plans', !!res.plans);
        sendMessageFromIframe({ status: 'loaded_plans' });
        sessionStorage.setItem('plans', JSON.stringify(res));
        dispatch(setPlans(res));
        navigate('/calc/plan');
      }

      // 222 case && 404 case
      if (res?.response?.data || res?.name) {
        console.log('222&404', !!res?.response?.data || !!res?.name);
        sendMessageFromIframe({ status: 'loaded_plans2' });
        navigate('/calc/open-plan');
      }

      if (!res || res?.error) {
        sendMessageFromIframe({
          status: 'error: load plans',
          message: JSON.stringify(res.error || res.message || 'error'),
        });
      }
    }
  };

  useEffect(() => {
    if (inputRef?.current && !extParam) {
      setTimeout(() => {
        (inputRef.current as any).focus();
      }, 99);
    }
  }, [options]);

  useEffect(() => {
    setOptions(null);
  }, [selected]);

  useEffect(() => {
    sessionStorage.removeItem('isReturn');
    sessionStorage.removeItem('address');
    sessionStorage.removeItem('name');
    sessionStorage.removeItem('roomsNum');
    sessionStorage.removeItem('plans');
    sessionStorage.removeItem('plan');
    sessionStorage.removeItem('innerHeight');
    dispatch(resetState());

    const handleMessage = (event: { data: { type: any; data: any } }) => {
      // if (event.origin !== 'https://trusted-domain.com') {
      //   return;
      // }
      console.log('Received data:', event.data);

      const data = event?.data?.data;
      const type = event?.data?.type;

      if (type === 'UTM') {
        sessionStorage.setItem('utm', JSON.stringify(data));
      }
      if (type === 'address') {
        if (data.innerHeight) {
          sessionStorage.setItem('innerHeight', data.innerHeight);
        }
        setValue(data.address);
        setSelected({ value: data.fias_id, label: data.address });
        setTimeout(() => {
          handleFinish(data);
        }, 333);
      }
    };

    window.addEventListener('message', handleMessage);

    sendMessageFromIframe({ status: 'initialized' });
    console.log('initialized');
    setTimeout(() => {
      console.log('1s after initialization');
      sendMessageFromIframe({ status: 'initialized' });
    }, 1000);

    return () => {
      window.removeEventListener('message', handleMessage);
    };
  }, []);

  if (extParam) return <div className="fixed top-0 left-0 right-0 bottom-0" />;

  return (
    <div className="w-full h-screen bg-[#000a] flex flex-col justify-center">
      <div
        className={clsx(
          'w-full lg:h-[500px] xl:px-[80px] px-[5vw] flex flex-col justify-center bg-white rounded-2xl font-bold text-gray-700 relative',
          'max-lg:pt-[60px] max-lg:pb-4 rounded-2xl',
        )}
        style={{ fontFamily: 'Calibri' }}
      >
        <div
          className="absolute bg-cover bg-center bg-no-repeat left-0 right-0 top-0 bottom-0 max-lg:hidden flex"
          style={{ backgroundImage: `url('/images/start_bg.jpg')` }}
        />
        <div
          className="absolute bg-cover bg-center bg-no-repeat left-0 right-0 top-0 bottom-0 max-lg:flex hidden"
          style={{ backgroundImage: `url('/images/start_bgv.jpg')` }}
        />
        <div className="z-50">
          <p className="mb-4 select-none xl:text-[32px] text-[25px]">
            Введите адрес дома,
            <br />
            где собираетесь менять окна, и узнайте их цену
          </p>
          <div className="xl:w-[800px] w-full md:min-w-[40vw] min-w-full">
            <Dropdown
              className="h-[64px] w-full"
              options={
                options?.map((el) => ({
                  label: el.unrestricted_value,
                  value: el.data.fias_id,
                  data: el,
                })) || null
              }
              onFinish={() => handleFinish(null)}
              setValue={setValue}
              setSelected={setSelected}
              onChange={(val: string) => {
                setValue(val);
                onInput(val);
              }}
              value={value}
              isLoading={isLoading}
              ref={inputRef}
            />
          </div>
          <div className="text-sm font-normal flex flex-col gap-2 mt-4 max-lg:mt-20 select-none">
            <div
              className="flex gap-1 items-center"
              role="button"
              tabIndex={0}
              onClick={() => {
                window?.open(
                  'https://www.youtube.com/watch?v=szPDeYMN1UA&ab_channel=%D0%A1%D0%BA%D0%B0%D1%87%D0%B0%D0%B9%D0%9E%D0%BA%D0%BD%D0%BE',
                  '_blank',
                );
              }}
            >
              <BuildingIcon /> Как это работает?
            </div>
            <div
              // className="gap-1 font-semibold items-center md:flex hidden"
              className="gap-1 font-semibold items-center flex"
              role="button"
              tabIndex={0}
            >
              <BuildingIcon /> Выбрать дом на карте
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
