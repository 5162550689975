// store.js
import { createSlice, configureStore } from '@reduxjs/toolkit';

import { useDispatch } from 'react-redux';

import { State } from './types';

const orderState = {
  profiles: {
    windows: [],
    balconies: [],
  },
  systems: {
    windows: [],
    balconies: [],
  },
  options: {
    windows: {},
    balconies: {},
  },
  items: {
    windows: [],
    balconies: [],
  },
  balconiesWorks: [],
  balconiesWarmGlass: [],
};

const initialState: State = {
  houseType: '',
  houseTypes: null,
  prices: {},
  isDiscount: false,
  requestCount: 0,
  user: null,
  userSettings: {},
  tokenData: null,
  windows: {},
  balconies: {},
  plans: null,
  rooms: null,
  isShowOrder: false,
  plan: -1,
  order: null,
  orderState,
  profilesInfo: null,
  pricesArr: [],
  enabled: {
    windows: [],
    balconies: [],
  },
  options: {
    windows: [],
    balconies: [],
  },
  names: {
    windows: {
      sill: [],
      handle: [],
      lamination_external: [],
      lamination_interior: [],
      extdoorsill: [],
      extwindsill: [],
    },
    balconies: {
      sill: [],
      handle: [],
      lamination_external: [],
      lamination_interior: [],
      extdoorsill: [],
      extwindsill: [],
    },
  },
  newOptionsToAll: null,
  customOptions: null,
};

const globalSlice = createSlice({
  name: 'global',
  initialState,
  reducers: {
    setPricesArr: (state, action) => {
      state.pricesArr = action.payload;
    },
    setCustomOptions: (state, action) => {
      state.customOptions = action.payload;
    },
    setHouseType: (state, action) => {
      state.houseType = action.payload;
    },
    setHouseTypes: (state, action) => {
      state.houseTypes = action.payload;
    },
    setNewOptionsToAll: (state, action) => {
      state.newOptionsToAll = action.payload;
    },
    setNames: (state, action) => {
      state.names = action.payload;
    },
    setName: (
      state,
      action: {
        payload: {
          type: 'windows' | 'balconies';
          key:
            | 'sill'
            | 'handle'
            | 'lamination_external'
            | 'lamination_interior'
            | 'extdoorsill'
            | 'extwindsill';
          index: number;
          value: any;
        };
        type: string;
      },
    ) => {
      if (
        (action.payload.type === 'windows' &&
          action.payload.key !== 'extdoorsill' &&
          action.payload.key !== 'extwindsill') ||
        action.payload.type === 'balconies'
      ) {
        if (
          state.names[action.payload.type][action.payload.key] &&
          state.names[action.payload.type]?.[action.payload.key]?.[action.payload.index] !==
            undefined
        ) {
          state.names[action.payload.type][action.payload.key][action.payload.index] =
            action.payload.value;
        }
      }
    },
    setDiscount: (state, action) => {
      state.isDiscount = action.payload;
    },
    startRequest: (state) => {
      state.requestCount += 1;
    },
    stopRequest: (state) => {
      state.requestCount = Math.max(0, state.requestCount - 1);
    },
    setPlan: (state, action) => {
      state.plan = action.payload;
    },
    setPlans: (state, action) => {
      state.plans = action.payload;
    },
    setWindows: (state, action) => {
      state.windows = action.payload;
    },
    setBalconies: (state, action) => {
      state.balconies = action.payload;
    },
    setUser: (state, action) => {
      state.user = action.payload;
    },
    setUserSettings: (state, action) => {
      state.userSettings = action.payload;
    },
    setToken: (state, action) => {
      state.tokenData = action.payload;
    },
    setRooms: (state, action) => {
      state.rooms = action.payload;
    },
    setShowOrder: (state, action) => {
      state.isShowOrder = action.payload;
    },
    setOrder: (state, action) => {
      state.order = action.payload;
    },
    setOrderState: (state, action) => {
      state.orderState = action.payload;
    },
    setWarm: (
      state,
      action: {
        payload: {
          index: number;
          value: any;
        };
        type: string;
      },
    ) => {
      state.orderState.balconiesWarmGlass[action.payload.index] = action.payload.value;
    },
    setEnabledIdx: (
      state,
      action: {
        payload: {
          type: 'windows' | 'balconies';
          index: number;
          value: any;
        };
        type: string;
      },
    ) => {
      if (action.payload.type === 'windows' || action.payload.type === 'balconies') {
        state.enabled[action.payload.type][action.payload.index] = action.payload.value;
      }
    },
    setBalconiesWorks: (
      state,
      action: {
        payload: {
          index: number;
          value: any;
        };
        type: string;
      },
    ) => {
      state.orderState.balconiesWorks[action.payload.index] = action.payload.value;
    },

    setEnabled: (state, action) => {
      state.enabled = action.payload;
    },
    setProfilesInfo: (state, action) => {
      state.profilesInfo = action.payload;
    },
    setOrderStateItem: (
      state,
      action: {
        payload: {
          type: 'windows' | 'balconies';
          index: number;
          value: any;
        };
        type: string;
      },
    ) => {
      if (action.payload.type === 'windows' || action.payload.type === 'balconies') {
        state.orderState.items[action.payload.type][action.payload.index] = action.payload.value;
      }
    },
    setOrderFloor: (state, action) => {
      state.order.floor = action.payload;
    },
    setOption: (
      state,
      action: {
        payload: { type: 'windows' | 'balconies'; index: number; value: any };
        type: string;
      },
    ) => {
      if (action.payload.type === 'windows' || action.payload.type === 'balconies') {
        state.options[action.payload.type][action.payload.index] = action.payload.value;
      }
    },
    restoreOption: (
      state,
      action: { payload: { type: 'windows' | 'balconies'; index: number; i: number } },
    ) => {
      if (action.payload.type === 'windows' || action.payload.type === 'balconies') {
        state.options[action.payload.type][action.payload.index][action.payload.i].selected = 0;
      }
    },
    deleteOption: (
      state,
      action: { payload: { type: 'windows' | 'balconies'; index: number; i: number } },
    ) => {
      if (action.payload.type === 'windows' || action.payload.type === 'balconies') {
        state.options[action.payload.type][action.payload.index][action.payload.i].selected = -1;
      }
    },
    selectOption: (
      state,
      action: {
        payload: { type: 'windows' | 'balconies'; index: number; i: number; selected: number };
      },
    ) => {
      if (action.payload.type === 'windows' || action.payload.type === 'balconies') {
        state.options[action.payload.type][action.payload.index][action.payload.i].selected =
          action.payload.selected;
      }
    },
    setPrices: (state, action) => {
      state.prices = action.payload;
    },
    resetState: () => initialState,
  },
});

export const {
  setPricesArr,
  setWarm,
  setBalconiesWorks,
  setCustomOptions,
  setHouseType,
  setHouseTypes,
  setNewOptionsToAll,
  setPrices,
  setDiscount,
  setName,
  setNames,
  startRequest,
  stopRequest,
  setWindows,
  setBalconies,
  setUser,
  setOrderFloor,
  setUserSettings,
  setToken,
  setPlan,
  setPlans,
  setRooms,
  setShowOrder,
  setOrder,
  setOrderState,
  setOrderStateItem,
  setOption,
  restoreOption,
  deleteOption,
  selectOption,
  setProfilesInfo,
  setEnabled,
  setEnabledIdx,
  resetState,
} = globalSlice.actions;

const store = configureStore({
  reducer: {
    global: globalSlice.reducer,
  },
  devTools: process.env.NODE_ENV !== 'production',
});

export default store;

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
export const useAppDispatch = () => useDispatch<AppDispatch>();
