import React, { useEffect, useLayoutEffect, useMemo, useRef, useState } from 'react';
import { Cell } from '../../components/Cell';
import BlockConfig from '../../components/BlockConfig';
import {
  RootState,
  selectOption,
  setDiscount,
  setEnabled,
  setName,
  setNames,
  setNewOptionsToAll,
  setOrder,
  setOrderState,
  setPrices,
  setPricesArr,
} from '../../redux/store';
import { useDispatch, useSelector } from 'react-redux';
import { TotalPrice } from '../../components/TotalPrice';
import { Room } from '../Calc/Plan/types';
import { checkIfBalcony, handleResize } from '../../utils/helpers';
import { getPrices, getPricesCustom } from '../../utils/httpServices/global';
import { useQuery } from 'react-query';
import ModalFloor from './ModalFloor';

interface Props {
  rooms?: Room[] | null;
}

const Configurator = ({ rooms }: Props) => {
  const dispatch = useDispatch();
  const confRef = useRef<HTMLDivElement | null>(null);

  const [isModalOpen, setIsModalOpen] = useState(false);
  const [pricesObj, setPricesObj] = useState<any>(null);
  const [isLoaded, setIsLoaded] = useState(false);
  const [isLoading1, setIsLoading1] = useState(false);

  const order = useSelector((state: RootState) => state.global.order);
  const orderState: any = useSelector((state: RootState) => state.global.orderState);
  const options = useSelector((state: RootState) => state.global.options);
  const names = useSelector((state: RootState) => state.global.names);
  const enabled = useSelector((state: RootState) => state.global.enabled);
  const profilesInfo = useSelector((state: RootState) => state.global.profilesInfo);
  const isDiscount = useSelector((state: RootState) => state.global.isDiscount);
  const newOptionsToAll = useSelector((state: RootState) => state.global.newOptionsToAll);
  const pricesArr = useSelector((state: RootState) => state.global.pricesArr);

  const { data: pricesData, isLoading } = useQuery(
    ['prices', order?.planId || 0, JSON.stringify(pricesObj)],
    () => {
      if (!pricesObj) return;
      setIsLoading1(true);
      if (order?.planId) {
        return getPrices(order.planId, pricesObj)
          .catch((error) => null)
          .finally(() => setIsLoading1(false));
      } else if (order?.planId === 0) {
        return getPricesCustom(pricesObj)
          .catch((error) => null)
          .finally(() => setIsLoading1(false));
      }
    },
  );

  const windowsArr1 = useMemo(
    () => (rooms && Array.isArray(rooms) ? rooms.filter((el) => !checkIfBalcony(el.name)) : []),
    [rooms],
  );
  const balconiesArr1 = useMemo(
    () => (rooms && Array.isArray(rooms) ? rooms.filter((el) => checkIfBalcony(el.name)) : []),
    [rooms],
  );

  useEffect(() => {
    if (!order) return;
    const orderData = JSON.parse(JSON.stringify(order));
    orderData.windowsArr1 = windowsArr1;
    orderData.balconiesArr1 = balconiesArr1;
    dispatch(setOrder(orderData));
  }, [windowsArr1, balconiesArr1, dispatch]);

  useLayoutEffect(() => {
    const isReturn = sessionStorage.getItem('isReturn');

    if (!orderState || !rooms || !options || isLoaded || isReturn) return;
    sessionStorage.removeItem('isReturn');

    const newData = JSON.parse(JSON.stringify(orderState));
    newData.profiles.windows = windowsArr1.map(() => 0);
    newData.systems.windows = windowsArr1.map(() => 0);
    newData.profiles.balconies = balconiesArr1.map(() => 0);
    newData.systems.balconies = balconiesArr1.map(() => 0);
    newData.balconiesWorks = balconiesArr1.map(() => false);
    newData.balconiesWarmGlass = balconiesArr1.map(() => false);
    newData.items.balconies = [];
    newData.items.windows = [];
    dispatch(setOrderState(newData));

    const enabled = {
      windows: windowsArr1.map(() => true),
      balconies: balconiesArr1.map(() => false),
    };
    dispatch(setEnabled(enabled));

    const getName = (el: any, key: string) =>
      (Object.values(el?.systems[0]?.constructions || {}) as any)?.[0]?.options?.[key]?.values?.[0]
        ?.name || '';

    const names = {
      windows: {
        sill: windowsArr1.map((el, i) => getName(el, 'sill')),
        handle: windowsArr1.map((el, i) => getName(el, 'handle')),

        lamination_external: windowsArr1?.map((el, i) => {
          const opt = options?.windows?.[i]?.find((item: any) => item.slug === 'lamination');
          const external = rooms[i]?.default_color?.external;
          const extColor =
            profilesInfo?.lamination?.values?.find((item: any) => item.id === external)?.name || '';

          if (opt?.selected !== undefined && opt?.selected >= 0) {
            if (opt?.values?.[0]?.name && extColor) {
              return extColor;
            }
          }
          return '';
        }),
        lamination_interior: windowsArr1?.map((el, i) => {
          const opt = options?.windows?.[i]?.find((item: any) => item.slug === 'lamination');
          const internal = rooms[i]?.default_color?.internal;
          const intColor =
            profilesInfo?.lamination?.values?.find((item: any) => item.id === internal)?.name || '';

          if (opt?.selected !== undefined && opt?.selected >= 0) {
            if (opt?.values?.[0]?.name && intColor) {
              return intColor;
            }
          }

          return '';
        }),
      },
      balconies: {
        sill: balconiesArr1.map((el, i) => getName(el, 'sill')),
        handle: balconiesArr1.map((el, i) => getName(el, 'handle')),

        lamination_external: balconiesArr1.map((el, i) => {
          const opt = options?.balconies?.[i]?.find((item: any) => item.slug === 'lamination');
          const external = rooms[i]?.default_color?.external;
          const extColor =
            profilesInfo?.lamination?.values?.find((item: any) => item.id === external)?.name || '';

          if (opt?.selected !== undefined && opt?.selected >= 0) {
            if (opt?.values?.[0]?.name && extColor) {
              return extColor;
            }
          }
          return '';
        }),
        lamination_interior: balconiesArr1.map((el, i) => {
          const opt = options?.balconies?.[i]?.find((item: any) => item.slug === 'lamination');
          const internal = rooms[i]?.default_color?.internal;
          const intColor =
            profilesInfo?.lamination?.values?.find((item: any) => item.id === internal)?.name || '';

          if (opt?.selected !== undefined && opt?.selected >= 0) {
            if (opt?.values?.[0]?.name && intColor) {
              return intColor;
            }
          }
          return '';
        }),
        extdoorsill: balconiesArr1.map((el) => getName(el, 'extdoorsill')),
        extwindsill: balconiesArr1.map((el) => getName(el, 'extwindsill')),
      },
    };

    dispatch(setNames(names));
    setIsLoaded(true);
  }, [dispatch, rooms, options]);

  useEffect(() => {
    if (!order || !orderState || !options || !names || !enabled || !profilesInfo) return;

    const data = {
      items: {},
      floor: order?.floor || 1,
      discount: isDiscount,
    };

    let iW = -1;
    let iB = -1;
    order?.planData?.forEach((el: any) => {
      const isBalcony = checkIfBalcony(el.name);
      if (isBalcony) iB++;
      else iW++;

      const profileIdx =
        orderState.profiles[isBalcony ? 'balconies' : 'windows'][isBalcony ? iB : iW];
      const openingIdx =
        orderState.systems[isBalcony ? 'balconies' : 'windows'][isBalcony ? iB : iW];

      const constructions = el.systems?.[profileIdx]?.constructions;

      const construction: any =
        typeof constructions === 'object' ? Object.values(constructions)[openingIdx] : {};
      const optionsData = options[isBalcony ? 'balconies' : 'windows'][isBalcony ? iB : iW];

      const opts: any = {};

      if (typeof optionsData === 'object' && Array.isArray(optionsData)) {
        optionsData.forEach((el: any) => {
          const key = el?.slug;
          if (typeof key === 'string')
            opts[key] = {
              enabled:
                el?.selected !== undefined && el.selected >= 0 && !!el.values?.[el.selected]?.id,
              id: el?.selected !== undefined && el.selected >= 0 ? el.values?.[el.selected]?.id : 0,
              name:
                el?.selected !== undefined && el.selected >= 0
                  ? el.values?.[el.selected]?.name
                  : '',
            };
        });
      }
      delete opts.lamination;

      const lamExtName =
        names?.[isBalcony ? 'balconies' : 'windows']?.lamination_external?.[isBalcony ? iB : iW] ||
        '';

      const lamIntName =
        names?.[isBalcony ? 'balconies' : 'windows']?.lamination_interior?.[isBalcony ? iB : iW] ||
        '';

      const lamExtId = profilesInfo?.lamination.values?.find(
        (el: any) => el.name === lamExtName,
      )?.id;
      const lamIntId = profilesInfo?.lamination.values?.find(
        (el: any) => el.name === lamIntName,
      )?.id;

      const lamOption = options?.[isBalcony ? 'balconies' : 'windows']?.[isBalcony ? iB : iW]?.find(
        (el: any) => el.slug === 'lamination',
      );

      opts.lamination_external = {
        enabled: lamOption && lamOption?.selected >= 0 && !!lamExtId,
        id: lamExtId,
      };
      opts.lamination_interior = {
        enabled: lamOption && lamOption?.selected >= 0 && !!lamIntId,
        id: lamIntId,
      };

      data.items = {
        ...data.items,
        [el.item_id.toString()]: {
          enabled: enabled?.[isBalcony ? 'balconies' : 'windows']?.[isBalcony ? iB : iW],
          name: el.name,
          construction_id: construction?.id || 0,
          options: opts,
        },
      };
    });

    const roomsData: any = data.items ? Object.values(data.items) : [];
    if (!roomsData.length) {
      console.error('Нет информации по комнатам');
      return;
    }
    if (roomsData.length > 0) {
      if (roomsData?.[0]?.options && Object.values(roomsData[0].options).length < 4) {
        console.error('Нет информации по конструкции');
        return;
      }
    }

    if (data) setPricesObj(data);
  }, [order, orderState, options, names, enabled, isDiscount, profilesInfo, dispatch]);

  useEffect(() => {
    if (pricesData) {
      dispatch(setPrices(pricesData));
      if (pricesData?.rooms) {
        // console.log('pricesArr', pricesArr);
        const obj = Object.values(pricesData.rooms).map((el: any) => el?.total);
        const newArr = [...pricesArr];
        newArr.push(obj);

        // console.log('obj', obj);
        // console.log('newArr', newArr);
        dispatch(setPricesArr(newArr));
      }
    }
  }, [pricesData]);

  useEffect(() => {
    if (newOptionsToAll) {
      if (newOptionsToAll.sill && options) {
        options.windows.forEach((_: any, i: number) => {
          const sillIdx = options?.windows?.[i]?.findIndex((el: any) => el.slug === 'sill');
          const selected = options?.windows?.[i]?.[sillIdx]?.values.findIndex(
            (el: any) => el.name === newOptionsToAll.sill,
          );

          if (sillIdx >= 0 && selected >= 0) {
            dispatch(selectOption({ type: 'windows', index: i, i: sillIdx, selected }));
            dispatch(
              setName({
                type: 'windows',
                key: 'sill',
                index: i,
                value: newOptionsToAll.sill,
              }),
            );
          }
        });

        options.balconies.forEach((_: any, i: number) => {
          const sillIdx = options?.balconies?.[i]?.findIndex((el: any) => el.slug === 'sill');
          const selected = options?.balconies?.[i]?.[sillIdx]?.values.findIndex(
            (el: any) => el.name === newOptionsToAll.sill,
          );

          if (sillIdx >= 0 && selected >= 0) {
            dispatch(selectOption({ type: 'balconies', index: i, i: sillIdx, selected }));
            dispatch(
              setName({
                type: 'balconies',
                key: 'sill',
                index: i,
                value: newOptionsToAll.sill,
              }),
            );
          }
        });
      }

      if (newOptionsToAll.handle && options) {
        options.windows.forEach((_: any, i: number) => {
          const handleIdx = options?.windows?.[i]?.findIndex((el: any) => el.slug === 'handle');
          const selected = options?.windows?.[i]?.[handleIdx]?.values.findIndex(
            (el: any) => el.name === newOptionsToAll.handle,
          );

          if (handleIdx >= 0 && selected >= 0) {
            dispatch(selectOption({ type: 'windows', index: i, i: handleIdx, selected }));
            dispatch(
              setName({
                type: 'windows',
                key: 'handle',
                index: i,
                value: newOptionsToAll.handle,
              }),
            );
          }
        });

        options.balconies.forEach((_: any, i: number) => {
          const handleIdx = options?.balconies?.[i]?.findIndex((el: any) => el.slug === 'handle');
          const selected = options?.balconies?.[i]?.[handleIdx]?.values.findIndex(
            (el: any) => el.name === newOptionsToAll.handle,
          );

          if (handleIdx >= 0 && selected >= 0) {
            dispatch(selectOption({ type: 'balconies', index: i, i: handleIdx, selected }));
            dispatch(
              setName({
                type: 'balconies',
                key: 'handle',
                index: i,
                value: newOptionsToAll.handle,
              }),
            );
          }
        });
      }

      if (newOptionsToAll.extdoorsill && options) {
        options.balconies.forEach((_: any, i: number) => {
          const extdoorsillIdx = options?.balconies?.[i]?.findIndex(
            (el: any) => el.slug === 'extdoorsill',
          );
          const selected = options?.balconies?.[i]?.[extdoorsillIdx]?.values.findIndex(
            (el: any) => el.name === newOptionsToAll.extdoorsill,
          );

          if (extdoorsillIdx >= 0 && selected >= 0) {
            dispatch(selectOption({ type: 'balconies', index: i, i: extdoorsillIdx, selected }));
            dispatch(
              setName({
                type: 'balconies',
                key: 'extdoorsill',
                index: i,
                value: newOptionsToAll.extdoorsill,
              }),
            );
          }
        });
      }

      if (newOptionsToAll.extwindsill && options) {
        options.balconies.forEach((_: any, i: number) => {
          const extwindsillIdx = options?.balconies?.[i]?.findIndex(
            (el: any) => el.slug === 'extwindsill',
          );
          const selected = options?.balconies?.[i]?.[extwindsillIdx]?.values.findIndex(
            (el: any) => el.name === newOptionsToAll.extwindsill,
          );

          if (extwindsillIdx >= 0 && selected >= 0) {
            dispatch(selectOption({ type: 'balconies', index: i, i: extwindsillIdx, selected }));
            dispatch(
              setName({
                type: 'balconies',
                key: 'extwindsill',
                index: i,
                value: newOptionsToAll.extwindsill,
              }),
            );
          }
        });
      }

      dispatch(setNewOptionsToAll(null));
    }
  }, [newOptionsToAll]);

  useEffect(() => {
    const isAnyEnabled = enabled?.windows?.some((el) => el) || enabled?.balconies?.some((el) => el);
    if (!isAnyEnabled) {
      dispatch(setDiscount(false));
    }
  }, [enabled]);

  useEffect(() => {
    setTimeout(() => {
      if (order?.floor && confRef.current && rooms) {
        console.log('scrollIntoView config');
        confRef.current.scrollIntoView({ block: 'start' });
      }
    }, 255);
  }, [rooms, order]);

  useEffect(() => {
    handleResize();
  }, [order, orderState, enabled, rooms, names, isDiscount, pricesData]);

  return (
    // <div ref={confRef} className="lg:p-8 p-0">
    <div ref={confRef}>
      <Cell
        className="max-lg:!px-0"
        header={
          <div className="font-Istok font-bold lg:text-[22px] text-[18px] flex items-center gap-[5px] leading-none max-lg:pl-4">
            Ваш этаж:
            {order?.floor ? (
              <div
                className="text-[#FF4545] underline cursor-pointer"
                role="button"
                onClick={(e) => {
                  const top = e?.currentTarget?.getBoundingClientRect().top;
                  if (top) {
                    sessionStorage.setItem('modalTop', (top > 100 ? top - 100 : top).toString());
                  }
                  setIsModalOpen(true);
                }}
              >
                {order?.floor}
              </div>
            ) : null}
          </div>
        }
        title={<div className="max-lg:px-4">Выберите окна, которые планируете менять</div>}
      >
        <div className="flex flex-col lg:gap-8 gap-6">
          {windowsArr1?.map((el, i) => (
            <BlockConfig
              isLoading={isLoading}
              key={JSON.stringify(el)}
              data={{ index: i, indexAbs: i, data: el, type: 'window' }}
            />
          ))}
          {balconiesArr1?.map((el, i) => (
            <BlockConfig
              isLoading={isLoading}
              key={JSON.stringify(el)}
              data={{
                index: i,
                indexAbs: i + (windowsArr1?.length || 0),
                data: el,
                type: 'balcony',
              }}
            />
          ))}
        </div>
      </Cell>

      <TotalPrice isLoading={isLoading} />

      <ModalFloor
        isLoading={isLoading || isLoading1}
        isModalOpen={isModalOpen}
        setIsModalOpen={setIsModalOpen}
      />
    </div>
  );
};

export default Configurator;
