export const APP_VERSION = '1.00';

// export const URL_API = process.env.REACT_APP_API_URL || 'https://dev.backend.mosokna.ru/api';
export const URL_API = process.env.REACT_APP_API_URL || 'https://prod.backend.mosokna.ru/api';

export const optionsItemsPerPage = [10, 25, 50, 100, 200];

export const MIN_LENGTH = 4;
export const DEBOUNCE_TIME_MS = 900;

export const HOUSE_TYPES = ['Панельный дом', 'Кирпичный', 'Сталинский', 'Новостройка'];

export const MAX_FLOOR = 45;

export const RQ_OPTS = {
  staleTime: 60000 * 15,
};
