import React, { useCallback, useEffect, useMemo, useState } from 'react';

import clsx from 'clsx';

import { useDispatch, useSelector } from 'react-redux';

import { FreeMode, Mousewheel, Navigation } from 'swiper/modules';
import { Swiper, SwiperClass, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import 'swiper/css/navigation';

import Button from '../Button';
import { Cell } from '../Cell';
import { Options } from '../Options';
import { Radio } from '../Radio';
import { ChevronDownIcon } from '../Icons/ChevronDown';
import { ChevronLeftIcon } from '../Icons/ChevronLeft';
import { ChevronRightIcon } from '../Icons/ChevronRight';
import { PlayIcon } from '../Icons/Play';
import { QuestionIcon } from '../Icons/Question';
import { Room } from '../../modules/Calc/Plan/types';

import { Item } from './Item';
import { Option } from './Option';
import {
  RootState,
  restoreOption,
  selectOption,
  setBalconiesWorks,
  setEnabledIdx,
  setName,
  setOption,
  setOrderState,
  setOrderStateItem,
  setWarm,
} from '../../redux/store';
import { Modal } from '../Modal';
import Spinner from '../Spinner';
import { nanoid } from 'nanoid';
import { OptionsBalcony } from '../OptionsBalcony';
import NavigationButton from './NavButton';

const ShoppingCartSimple = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="22" height="22" viewBox="0 0 22 22" fill="none">
    <path
      d="M8.25 18.5625C8.25 18.8344 8.16936 19.1003 8.01827 19.3264C7.86718 19.5525 7.65244 19.7288 7.40119 19.8328C7.14994 19.9369 6.87347 19.9641 6.60675 19.9111C6.34003 19.858 6.09503 19.7271 5.90273 19.5348C5.71043 19.3425 5.57947 19.0975 5.52642 18.8307C5.47337 18.564 5.5006 18.2876 5.60467 18.0363C5.70874 17.7851 5.88497 17.5703 6.11109 17.4192C6.33721 17.2681 6.60305 17.1875 6.875 17.1875C7.23967 17.1875 7.58941 17.3324 7.84727 17.5902C8.10513 17.8481 8.25 18.1978 8.25 18.5625ZM15.8125 17.1875C15.5406 17.1875 15.2747 17.2681 15.0486 17.4192C14.8225 17.5703 14.6462 17.7851 14.5422 18.0363C14.4381 18.2876 14.4109 18.564 14.4639 18.8307C14.517 19.0975 14.6479 19.3425 14.8402 19.5348C15.0325 19.7271 15.2775 19.858 15.5443 19.9111C15.811 19.9641 16.0874 19.9369 16.3387 19.8328C16.5899 19.7288 16.8047 19.5525 16.9558 19.3264C17.1069 19.1003 17.1875 18.8344 17.1875 18.5625C17.1875 18.1978 17.0426 17.8481 16.7848 17.5902C16.5269 17.3324 16.1772 17.1875 15.8125 17.1875ZM19.8034 5.7793C19.7395 5.69266 19.6561 5.62224 19.56 5.57373C19.4639 5.52522 19.3577 5.49996 19.25 5.5H4.1525L3.45555 3.05937C3.37237 2.77264 3.19856 2.52055 2.96015 2.34085C2.72173 2.16115 2.43152 2.06349 2.13297 2.0625H0.6875C0.505164 2.0625 0.330295 2.13493 0.201364 2.26386C0.0724328 2.3928 0 2.56766 0 2.75C0 2.93234 0.0724328 3.1072 0.201364 3.23614C0.330295 3.36507 0.505164 3.4375 0.6875 3.4375H2.13297L5.24219 14.3163C5.36661 14.7465 5.62703 15.1247 5.98444 15.3944C6.34184 15.6641 6.77701 15.8108 7.22477 15.8125H15.4842C15.9257 15.814 16.3558 15.6733 16.711 15.411C17.0661 15.1488 17.3272 14.7791 17.4556 14.3567L19.9074 6.38945C19.939 6.2867 19.946 6.17798 19.928 6.07201C19.9099 5.96605 19.8673 5.86579 19.8034 5.7793Z"
      fill="white"
    />
  </svg>
);

export type OrderItem = {
  name: string;
  desc: string;
  price: number;
  isChangeble: boolean;
  isRemovable: boolean;
  src: string;
};

interface Props {
  isLoading?: boolean;
  data: {
    index: number;
    indexAbs: number;
    data: Room;
    type: 'window' | 'balcony';
  };
}

export default function BlockConfig(props: Props) {
  const dispatch = useDispatch();
  const [showPrevButton, setShowPrevButton] = useState(false);
  const [showNextButton, setShowNextButton] = useState(false);
  const [swiper, setSwiper] = useState<null | SwiperClass>(null);
  const { index, indexAbs, data, type } = props.data;
  const isLoading = props.isLoading || false;

  const [key, setKey] = useState<string | number>('');
  const [play, setPlay] = useState(0);
  const [showOpenModal, setShowOpenModal] = useState(false);
  const [isOptionsOpen, setIsOptionsOpen] = useState(-1);
  const [showAdditionalOpt, setShowAdditionalOpt] = useState(false);
  const [showAdditionalOptB, setShowAdditionalOptB] = useState(false);
  const [showFinishing, setShowFinishing] = useState(true);
  const orderState = useSelector((state: RootState) => state.global.orderState);
  const options = useSelector((state: RootState) => state.global.options);
  const enabled = useSelector((state: RootState) => state.global.enabled);
  const prices = useSelector((state: RootState) => state.global.prices);
  const order = useSelector((state: RootState) => state.global.order);
  const profilesInfo = useSelector((state: RootState) => state.global.profilesInfo);
  const pricesArr = useSelector((state: RootState) => state.global.pricesArr);

  // const roomId = props?.data?.data?.id;
  const itemId = props?.data?.data?.item_id;

  const typeKey = type === 'window' ? 'windows' : 'balconies';
  const thisOptions = options?.[typeKey]?.[index] || [];
  const thisOptionsUnselected = thisOptions
    ?.map((el: any, i: number) => (el?.selected === -1 ? { ...el, i } : null))
    ?.filter(Boolean);

  const ress: any = useMemo(
    () => data?.systems?.[orderState.profiles?.[typeKey]?.[index]],
    [data?.systems, index, orderState.profiles, typeKey],
  );

  const res: any = useMemo(() => ress?.constructions, [ress?.constructions]);

  const resVal: any = useMemo(
    () => (res ? Object.values(res)[orderState.systems?.[typeKey]?.[index]] : null),
    [index, orderState.systems, res, typeKey],
  );

  const roomData = order?.planData?.[indexAbs];
  const profiles = useMemo(
    () =>
      profilesInfo?.systems.filter((el: any) =>
        roomData?.systems?.find((item: any) => item.name === el.name),
      ),
    [profilesInfo, roomData],
  );

  const isOnlyCold = profiles?.every((el: any) => el.is_cold);
  const isOnlyWarm = profiles?.every((el: any) => !el.is_cold);

  useEffect(() => {
    if (resVal) {
      // const newData = JSON.parse(JSON.stringify(orderState));
      // const val = newData?.items?.[typeKey]?.[index];
      // const selected = val?.selected || 0;
      // const changable = val?.changable && val.values.length > 1; // если только 1 опция её нельзя изменить, только удалить

      dispatch(setOrderStateItem({ type: typeKey, index, value: resVal }));
      dispatch(
        setOption({
          type: typeKey,
          index,
          value: Object.values(resVal.options).map((val: any) =>
            val
              ? {
                  ...val,
                  selected:
                    val.selected !== undefined
                      ? val.selected
                      : (val.enabled && !val.is_finishing) ||
                        (val.is_finishing && orderState?.balconiesWorks?.[index] && val.enabled)
                      ? 0
                      : -1,
                  editable: val.values.length > 1,
                }
              : null,
          ),
        }),
      );
    }
  }, [resVal]);

  const handleModalPosition = (e: React.MouseEvent<HTMLElement>) => {
    const top = e?.currentTarget?.getBoundingClientRect().top;
    if (top) {
      const newTop = top > 200 ? top - 200 : top;
      sessionStorage.setItem('modalTop', newTop.toString());
    }
  };

  const handleBalconyWorks = useCallback(
    (newVal: boolean) => {
      if (!orderState) return;
      // const newData: any = JSON.parse(JSON.stringify(orderState));
      // newData.balconiesWorks[index] = newVal;
      // dispatch(setOrderState(newData));
      dispatch(setBalconiesWorks({ index, value: newVal }));

      const opts = options?.[typeKey]?.[index];
      opts.forEach((el: any, i: number) => {
        if (el.is_finishing) {
          dispatch(
            selectOption({
              type: typeKey,
              index,
              i,
              selected: newVal && el.enabled ? 0 : -1,
            }),
          );
        }
      });

      // if (!newVal) {
      //   setShowFinishing(false);
      // }
    },
    [dispatch, index, options, orderState, typeKey],
  );

  const handleEnable = useCallback(
    (value: boolean) => {
      if (!value && type === 'balcony') {
        handleBalconyWorks(false);
      }
      dispatch(setEnabledIdx({ type: typeKey, index, value }));
    },
    [dispatch, handleBalconyWorks, index, type, typeKey],
  );

  const handleOpen = useCallback(
    (val: number) => {
      let length = 0;
      if (res && typeof res === 'object') {
        length = Object.values(res).length;
      }

      const newData: any = JSON.parse(JSON.stringify(orderState));
      let value = newData?.systems?.[typeKey]?.[index];
      if (value !== undefined && typeof value === 'number') {
        newData.systems[typeKey][index] = (value + val + length) % length;
        dispatch(setOrderState(newData));
        setKey((value + val + length) % length);
      }
    },
    [dispatch, index, orderState, res, typeKey],
  );

  const setBalconyWarn = (value: boolean) => {
    if (!orderState) return;
    dispatch(setWarm({ index, value }));
  };

  const currentItem = profilesInfo?.systems?.find((el: any) => el.name === ress?.name);

  useEffect(() => {
    setPlay(0);
  }, [orderState.systems]);

  useEffect(() => {
    if (play) {
      setTimeout(() => setPlay(0), 5000);
    }
  }, [play]);

  const ps = profilesInfo?.systems?.find((el: any) => el?.name === ress?.name);
  // const desc = ps?.description || ps?.info.description;
  const desc = ps?.info.description;

  const SystemInfo = () => (
    <div className="font-normal">
      <div className="lg:text-[20px] text-[15px] lg:mb-3 mb-1">{ress?.name}</div>
      <div
        // className="lg:text-[15px] text-[13px] lg:leading-none leading-[15px] max-lg:text-[#B4B4B4]"
        className="lg:text-[15px] text-[13px] lg:leading-none leading-[15px]"
        dangerouslySetInnerHTML={{
          // __html: desc || ress?.description,
          __html: desc,
        }}
      />
    </div>
  );

  const drawAddOption = (el: any) => (
    <Option
      key={JSON.stringify(el)}
      data={el}
      full={showAdditionalOptB}
      prices={prices?.rooms?.[itemId]?.options}
      restore={() => {
        dispatch(restoreOption({ type: typeKey, index, i: el.i }));
        if (el.slug !== 'lamination') {
          dispatch(
            setName({
              type: typeKey,
              key: el.slug,
              index,
              value: el.values[0].name,
            }),
          );
        } else {
          dispatch(
            setName({
              type: typeKey,
              key: 'lamination_external',
              index,
              value: el.values[0].name,
            }),
          );
          // dispatch(
          //   setName({
          //     type: typeKey,
          //     key: 'lamination_interior',
          //     index,
          //     value: el.values[0].name,
          //   }),
          // );
        }
      }}
    />
  );

  const handleOptionClick = useCallback((slug: string, e: React.MouseEvent<HTMLElement>) => {
    handleModalPosition(e);

    setIsOptionsOpen(
      slug === 'sill'
        ? 3
        : slug === 'handle'
        ? 4
        : slug === 'lamination'
        ? 2
        : slug === 'extdoorsill'
        ? 5
        : slug === 'extwindsill'
        ? 6
        : slug === 'floorfinish'
        ? 7
        : slug === 'loggdecor'
        ? 8
        : slug === 'loggdecor2'
        ? 9
        : 0,
    );
  }, []);

  const showOption = (el: any) => (
    <Option
      key={JSON.stringify(el)}
      data={el}
      full={showAdditionalOpt}
      prices={prices?.rooms?.[itemId]?.options}
      restore={() => {
        dispatch(restoreOption({ type: typeKey, index, i: el.i }));
        if (el.slug !== 'lamination') {
          dispatch(
            setName({
              type: typeKey,
              key: el.slug,
              index,
              value: el.values[0].name,
            }),
          );
        } else {
          dispatch(
            setName({
              type: typeKey,
              key: 'lamination_external',
              index,
              value: el.values[0].name,
            }),
          );
          // dispatch(
          //   setName({
          //     type: typeKey,
          //     key: 'lamination_interior',
          //     index,
          //     value: el.values[0].name,
          //   }),
          // );
        }
      }}
    />
  );

  const priceF = useMemo(
    () =>
      typeKey === 'balconies' && prices?.rooms?.[itemId]?.enabled ? prices?.rooms?.[itemId] : 0,
    [itemId, prices, typeKey],
  );

  const priceFOpts = useMemo(
    () =>
      priceF &&
      Object.values(priceF.options).reduce(
        (acc: number, cur: any) =>
          acc +
          (cur?.enable &&
          options?.[typeKey]?.[index]?.find((el: any) => el.slug === cur.slug)?.is_finishing
            ? cur.price
            : 0),
        0,
      ),
    [index, options, priceF, typeKey],
  );

  const onSwiper = (instance: any) => {
    setSwiper(instance);
  };

  useEffect(() => {
    if (swiper) {
      const checkNavigation = () => {
        setShowPrevButton(!swiper.isBeginning);
        setShowNextButton(!swiper.isEnd);
      };

      checkNavigation();
      swiper.on('slideChange', checkNavigation);

      return () => {
        swiper.off('slideChange', checkNavigation);
      };
    }
  }, [swiper]);

  return (
    <>
      <div
        className="rounded-[20px] bg-white p-5"
        style={{ boxShadow: '0px 36px 19.6px 0px rgba(37, 35, 35, 0.09)' }}
      >
        <div
          className={clsx(
            'flex flex-auto max-lg:flex-col',
            enabled?.[typeKey]?.[index] ? 'gap-[3%]' : 'gap-[1%]',
          )}
        >
          <div
            className={clsx(
              'flex flex-col max-lg:!w-full',
              !enabled?.[typeKey]?.[index] ? 'w-[34%]' : 'w-1/2',
            )}
          >
            <h2 className="font-Istok font-bold lg:text-[30px] text-[24px] text-[#FF4545]">
              {data?.name}
            </h2>
            <p
              className="lg:text-[20px] text-[15px] lg:mb-10 max-lg:mb-4 transition-all"
              // style={{ opacity: enabled?.[typeKey]?.[index] ? 1 : 0 }}
            >
              Выберите опции для {type === 'window' ? 'окна' : 'лоджии'}
            </p>

            <Cell className="lg:hidden max-lg:!mb-4">
              <div className="flex items-center justify-between gap-3 w-full">
                {/* <Selector
                  checked={!!enabled?.[typeKey]?.[index]}
                  onChange={() => handleEnable(!!!enabled?.[typeKey]?.[index])}
                /> */}
                <div className="font-Istok">Изготовление и установка</div>
                <Button
                  variant={!!enabled?.[typeKey]?.[index] ? 'outline' : 'red'}
                  onClick={() => handleEnable(!!!enabled?.[typeKey]?.[index])}
                >
                  {!!enabled?.[typeKey]?.[index] ? (
                    <div className="lg:w-[136px] w-[97px]">Удалить</div>
                  ) : (
                    <div className="flex gap-2">
                      <ShoppingCartSimple /> Добавить
                    </div>
                  )}
                </Button>
              </div>
              {type === 'balcony' ? (
                <div
                  className={clsx(
                    'flex justify-between items-center gap-3 mt-3 border-t-[#B4B4B4] border-t-solid border-t-[1px] pt-3',
                    !!!enabled?.[typeKey]?.[index] && '!opacity-50 !cursor-not-allowed',
                  )}
                >
                  {/* <Selector
                    checked={orderState.balconiesWorks[index]}
                    onChange={() => handleBalconyWorks(!orderState.balconiesWorks[index])}
                    disabled={!!!enabled?.[typeKey]?.[index]}
                  /> */}
                  <div className="font-Istok">Отделка лоджии</div>
                  <Button
                    variant={orderState.balconiesWorks[index] ? 'outline' : 'red'}
                    onClick={() => handleBalconyWorks(!orderState.balconiesWorks[index])}
                    disabled={!!!enabled?.[typeKey]?.[index]}
                  >
                    {orderState.balconiesWorks[index] ? (
                      <div className="lg:w-[136px] w-[97px]">Удалить</div>
                    ) : (
                      <div className="flex gap-2">
                        <ShoppingCartSimple /> Добавить
                      </div>
                    )}
                  </Button>
                </div>
              ) : null}
            </Cell>
            {/* <div
              className="w-full h-[20px] bg-[white]"
              style={{
                boxShadow: `0px 36px 19.6px 0px rgba(37, 35, 35, 0.29)`,
                borderRadius: '0px 0px 20px 20px',
              }}
            ></div> */}

            <div className="font-Istok font-bold text-[22px]">{resVal?.name || <>&nbsp;</>}</div>
            <div
              className={clsx(
                'lg:flex block gap-5 lg:w-auto w-full',
                enabled?.[typeKey]?.[index] ? 'mt-3 lg:flex-row flex-col' : 'mt-auto pt-2',
              )}
            >
              <div
                className={clsx(
                  'aspect-square h-fit rounded-[18px] relative overflow-hidden border bg-no-repeat bg-contain',
                  play && 'cursor-none',
                  enabled?.[typeKey]?.[index]
                    ? 'lg:w-1/2 w-full lg:mb-0 mb-3'
                    : 'lg:w-[60%] !w-full',
                )}
                style={{
                  backgroundImage: `url(${
                    play ? `${resVal?.gif_path}?${play}` : resVal?.image_path
                  })`,
                }}
              >
                {!play && (
                  <button
                    className="absolute top-[10px] left-[10px] transition-all"
                    onClick={() => setPlay(new Date().valueOf())}
                  >
                    <PlayIcon />
                  </button>
                )}
              </div>
              <div
                className={clsx(
                  'h-full',
                  !enabled?.[typeKey]?.[index] && 'flex flex-col justify-end',
                  enabled?.[typeKey]?.[index] ? 'lg:w-1/2 w-full' : 'w-[40%]',
                )}
              >
                {!!order?.planId && data?.image_mask_path && (
                  <>
                    <div className="font-Istok font-bold text-base mb-2 lg:block hidden">
                      Расположение
                    </div>
                    <Cell
                      className={clsx(
                        'lg:block hidden',
                        enabled?.[typeKey]?.[index] ? 'w-[60%] mb-4' : 'w-[90%] !mb-0',
                      )}
                    >
                      <div
                        className="w-full aspect-square relative bg-no-repeat bg-contain"
                        style={{ backgroundImage: `url(${data.image_mask_path})` }}
                      />
                    </Cell>
                  </>
                )}

                {enabled?.[typeKey]?.[index] && (
                  <Cell className="!mb-0">
                    <div className="font-Istok font-bold lg:text-base text-sm mb-2 flex items-center gap-1">
                      Тип открывания
                      <QuestionIcon
                        role="button"
                        tabIndex={0}
                        onClick={(e) => {
                          const top = e?.currentTarget?.getBoundingClientRect().top;
                          if (top) {
                            sessionStorage.setItem(
                              'modalTop',
                              (top > 100 ? top - 100 : top).toString(),
                            );
                          }
                          setShowOpenModal(true);
                        }}
                      />
                    </div>
                    <div className="font-normal lg:text-base text-sm">
                      {resVal?.name || <>&nbsp;</>}{' '}
                    </div>
                    <div className="flex w-full justify-between items-center">
                      <div className="flex gap-2 flex-wrap mt-1">
                        {Array(res && typeof res === 'object' ? Object.values(res).length : 0)
                          .fill(0)
                          .map((_, i) => (
                            <div
                              key={i}
                              className="w-3 h-3 rounded-sm"
                              style={{
                                background:
                                  orderState.systems?.[typeKey]?.[index] === i
                                    ? 'radial-gradient(50% 50% at 50% 50%, #2F2F2F 0%, #1A1A1A 100%)'
                                    : '#d4d4d4',
                              }}
                            />
                          ))}
                      </div>

                      <div className="flex gap-3">
                        <button
                          className="p-1 rounded-[5px] bg-[#3E3E40]"
                          onClick={() => handleOpen(-1)}
                        >
                          <ChevronLeftIcon />
                        </button>
                        <button
                          className="p-1 rounded-[5px] bg-[#3E3E40]"
                          onClick={() => handleOpen(1)}
                        >
                          <ChevronRightIcon />
                        </button>
                      </div>
                    </div>
                  </Cell>
                )}

                {!!order?.planId && data?.image_mask_path && (
                  <>
                    <div className="font-Istok font-bold lg:text-base text-sm mb-2 lg:hidden block mt-4">
                      Расположение
                    </div>
                    <Cell
                      className={clsx(
                        'lg:hidden block',
                        enabled?.[typeKey]?.[index] ? 'w-1/2 mb-4' : 'w-[90%] !mb-0',
                      )}
                    >
                      <div
                        className="w-full aspect-square relative bg-no-repeat bg-contain"
                        style={{ backgroundImage: `url(${data.image_mask_path})` }}
                      />
                    </Cell>
                  </>
                )}
              </div>
            </div>
            <div
              className="flex flex-col transition-all overflow-hidden"
              style={{ display: enabled?.[typeKey]?.[index] ? 'flex' : 'none' }}
            >
              <div className="w-full h-[1px] bg-[#B4B4B4] lg:mt-[30px] my-3" />
              <div
                className={clsx(
                  'flex lg:gap-x-2 lg:gap-y-4 gap-2',
                  type === 'window' && 'max-lg:items-center',
                  type === 'balcony' && 'max-lg:flex-col',
                )}
              >
                {type === 'window' ? (
                  <>
                    <div className="font-Istok text-base lg:w-1/2">
                      Оконная система
                      <div className="lg:hidden block">
                        <SystemInfo />
                      </div>
                    </div>
                    <div className="lg:flex hidden">
                      <SystemInfo />
                    </div>
                    <Button
                      variant="outline"
                      className="ml-auto h-fit"
                      onClick={(e) => {
                        setIsOptionsOpen(0);
                        handleModalPosition(e);
                      }}
                    >
                      Изменить
                    </Button>
                  </>
                ) : (
                  <>
                    <div className="flex flex-col lg:w-1/2">
                      <div className="flex w-full justify-between">
                        <div className="font-Istok text-base">Оконная система</div>
                        <Button
                          variant={'outline'}
                          onClick={(e) => {
                            setIsOptionsOpen(0);
                            handleModalPosition(e);
                          }}
                        >
                          Изменить
                        </Button>
                      </div>
                      <SystemInfo />
                    </div>
                    <div className="w-full h-[1px] bg-[#B4B4B4] lg:hidden block" />
                    <div className="flex flex-col w-1/2">
                      <div className="font-Istok text-base mb-3">Тип остекления</div>
                      <div className="font-normal text-base text-[20px]">
                        <div
                          className={clsx(
                            'flex lg:text-[20px] text-sm gap-1 items-center cursor-pointer lg:mb-1 mb-3',
                            isOnlyWarm ? 'opacity-50' : 'cursor-pointer',
                          )}
                          onClick={() => {
                            if (!isOnlyWarm) {
                              setBalconyWarn(false);
                              setKey(nanoid());
                              setTimeout(() => {
                                setBalconyWarn(false);
                                setKey(nanoid());
                              }, 222);
                            }
                          }}
                        >
                          <Radio checked={currentItem?.is_cold || false} disabled={isOnlyWarm} />
                          Холодное остекление
                        </div>
                        <div
                          className={clsx(
                            'flex lg:text-[20px] text-sm gap-1 items-center',
                            isOnlyCold ? 'opacity-50' : 'cursor-pointer',
                          )}
                          onClick={() => {
                            if (!isOnlyCold) {
                              setBalconyWarn(true);
                              setKey(nanoid());
                              setTimeout(() => {
                                setBalconyWarn(true);
                                setKey(nanoid());
                              }, 222);
                            }
                          }}
                        >
                          <Radio checked={!currentItem?.is_cold || false} disabled={isOnlyCold} />
                          Теплое остекление
                        </div>
                      </div>
                    </div>
                  </>
                )}
              </div>
            </div>
          </div>
          <div
            className={clsx(
              'flex flex-col text-base font-bold max-lg:!w-full max-lg:mt-5',
              !enabled?.[typeKey]?.[index] ? 'w-[66%]' : 'w-1/2',
            )}
          >
            <Cell className="max-lg:hidden">
              <div className="flex items-center gap-3 w-full justify-between">
                {/* <Selector
                  checked={!!enabled?.[typeKey]?.[index]}
                  onChange={() => handleEnable(!!!enabled?.[typeKey]?.[index])}
                /> */}
                <div className="font-Istok">Изготовление и установка</div>
                <Button
                  variant={!!enabled?.[typeKey]?.[index] ? 'outline' : 'red'}
                  onClick={() => handleEnable(!!!enabled?.[typeKey]?.[index])}
                >
                  {!!enabled?.[typeKey]?.[index] ? (
                    <div className="lg:w-[136px] w-[97px]">Удалить</div>
                  ) : (
                    <div className="flex gap-2">
                      <ShoppingCartSimple /> Добавить
                    </div>
                  )}
                </Button>
              </div>
              {type === 'balcony' ? (
                <div
                  className={clsx(
                    'flex justify-between items-center gap-3 mt-3 border-t-[#B4B4B4] border-t-solid border-t-[1px] pt-3',
                    !!!enabled?.[typeKey]?.[index] && '!opacity-50 !cursor-not-allowed',
                  )}
                >
                  {/* <Selector
                    checked={orderState.balconiesWorks[index]}
                    onChange={() => handleBalconyWorks(!orderState.balconiesWorks[index])}
                    disabled={!!!enabled?.[typeKey]?.[index]}
                  /> */}
                  <div className="font-Istok">Отделка лоджии</div>
                  <Button
                    variant={orderState.balconiesWorks[index] ? 'outline' : 'red'}
                    onClick={() => handleBalconyWorks(!orderState.balconiesWorks[index])}
                    disabled={!!!enabled?.[typeKey]?.[index]}
                  >
                    {orderState.balconiesWorks[index] ? (
                      <div className="lg:w-[136px] w-[97px]">Удалить</div>
                    ) : (
                      <div className="flex gap-2">
                        <ShoppingCartSimple /> Добавить
                      </div>
                    )}
                  </Button>
                </div>
              ) : null}
            </Cell>

            {!enabled?.[typeKey]?.[index] && (
              <Cell
                style={{
                  background: 'radial-gradient(74.5% 74.5% at 50% 50%, #FFF 0%, #DEDEDE 100%)',
                }}
                classNameContainer="flex justify-between gap-x-4 max-lg:flex-col"
                className="!mb-0 max-lg:!p-[1px]"
              >
                {prices?.rooms?.[itemId]?.total || pricesArr?.[0]?.[indexAbs] ? (
                  <div className="font-bold flex-col justify-between lg:flex hidden">
                    <div>
                      <div className="font-Istok text-[18px] mb-[6px]">Цена окна</div>
                      <div className="font-Istok text-[32px] whitespace-nowrap">
                        {isLoading ? (
                          <Spinner />
                        ) : (
                          prices?.rooms?.[itemId]?.total || pricesArr?.[0]?.[indexAbs]
                        )}{' '}
                        ₽
                      </div>
                    </div>
                  </div>
                ) : null}

                <div className="w-full">
                  <Cell className="bg-white font-normal !mb-0 !pr-0" classNameContainer="">
                    <p className="font-Istok text-[18px] font-bold mb-4">Что входит в заказ</p>
                    {/* список опций у выключенного окна */}

                    <Swiper
                      slidesPerView={'auto'}
                      className={'cursor-grab active:cursor-grabbing select-none relative !pr-4'}
                      modules={[FreeMode, Mousewheel, Navigation]}
                      mousewheel={{ forceToAxis: true }}
                      freeMode={{ enabled: true, sticky: false }}
                      onSlideChange={(e: SwiperClass) => {
                        setShowNextButton(!e?.isEnd);
                        setShowPrevButton(!e?.isBeginning);
                      }}
                      onSwiper={onSwiper}
                    >
                      {showPrevButton && (
                        <NavigationButton
                          direction="prev"
                          className="lg:left-4 left-3"
                          onClick={() => {
                            swiper?.slidePrev();
                            setTimeout(() => {
                              if (swiper) {
                                swiper.update();
                                setShowNextButton(!swiper.isEnd);
                                setShowPrevButton(!swiper.isBeginning);
                                setSwiper(swiper);
                              }
                            }, 450);
                          }}
                        />
                      )}
                      {showNextButton && (
                        <NavigationButton
                          direction="next"
                          className="lg:right-4 right-3"
                          onClick={() => {
                            swiper?.slideNext();
                            setTimeout(() => {
                              if (swiper) {
                                swiper.update();
                                setShowNextButton(!swiper.isEnd);
                                setShowPrevButton(!swiper.isBeginning);
                                setSwiper(swiper);
                              }
                            }, 450);
                          }}
                        />
                      )}
                      {thisOptions
                        ?.filter((el: any) => el.enabled && !el.is_finishing)
                        .map((el: any, i: number) =>
                          el.selected !== undefined && el.selected !== -1 ? (
                            <SwiperSlide
                              className={`!w-auto mr-4 last:mr-0`}
                              key={JSON.stringify(el) + i}
                            >
                              <div
                                key={JSON.stringify(el) + i}
                                className={'lg:w-[88px] w-[48px] flex flex-col gap-[6px]'}
                                style={el?.sort !== undefined ? { order: el.sort } : {}}
                              >
                                <div
                                  className="w-full aspect-square bg-cover bg-no-repeat rounded-[8px] border-2 border-[#B4B4B4]"
                                  style={{ backgroundImage: `url(${el.image})` }}
                                />
                                <div className="flex flex-wrap text-xs line-clamp-2 hover:line-clamp-3 overflow-hidden">
                                  {el.name}
                                </div>
                              </div>
                            </SwiperSlide>
                          ) : null,
                        )}
                    </Swiper>
                  </Cell>
                </div>
                {prices?.rooms?.[itemId]?.total || pricesArr?.[0]?.[indexAbs] ? (
                  <div className="font-bold items-center justify-between lg:hidden flex px-4 py-3 text-sm">
                    <div className="font-Istok">Цена окна</div>
                    <div className="font-Istok whitespace-nowrap">
                      {isLoading ? (
                        <Spinner />
                      ) : (
                        prices?.rooms?.[itemId]?.total || pricesArr?.[0]?.[indexAbs]
                      )}{' '}
                      ₽
                    </div>
                  </div>
                ) : null}
              </Cell>
            )}

            <div
              className="flex flex-col transition-all overflow-hidden "
              style={{ display: enabled?.[typeKey]?.[index] ? 'flex' : 'none' }}
            >
              <div className="font-Istok lg:text-[22px] text-[19px] lg:mt-[44px] mt-2 mb-4">
                Что входит в заказ
              </div>
              {/* список опций у ВКЛЮЧЕННОГО окна */}
              <div
                className={clsx(
                  'mb-1 pr-1 flex flex-col',
                  thisOptions.filter(
                    (el: any, i: number) =>
                      el.selected !== undefined && el.selected !== -1 && !el.is_finishing,
                  )?.length > 6 && 'overflow-auto !max-h-[50vh]',
                )}
              >
                {thisOptions.map((el: any, i: number) =>
                  el.selected !== undefined && el.selected !== -1 && !el.is_finishing ? (
                    <Item
                      prices={prices?.rooms?.[itemId]?.options}
                      data={el}
                      key={JSON.stringify(el)}
                      onClick={(e: React.MouseEvent<HTMLElement>) => {
                        handleOptionClick(el.slug, e);
                      }}
                      type={typeKey}
                      index={index}
                      i={i}
                    />
                  ) : null,
                )}
              </div>
              <div className="rounded-lg p-4 bg-[#3E3E40] text-white">
                <div className="font-Istok text-base">Дополнительные опции:</div>

                {showAdditionalOpt ? (
                  <div
                    className={clsx(
                      showAdditionalOpt ? 'flex flex-col' : 'flex',
                      'w-full lg:gap-4 gap-2 my-4 flex-wrap',
                    )}
                  >
                    {(thisOptionsUnselected?.filter((el: any) => !el.is_finishing) || []).map(
                      (el: any) => showOption(el),
                    )}
                  </div>
                ) : (
                  <Swiper
                    slidesPerView={'auto'}
                    className={'cursor-grab active:cursor-grabbing select-none my-4'}
                    modules={[FreeMode, Mousewheel]}
                    mousewheel={{ forceToAxis: true }}
                    freeMode={{ enabled: true, sticky: false }}
                  >
                    {(thisOptionsUnselected?.filter((el: any) => !el.is_finishing) || []).map(
                      (el: any) => (
                        <SwiperSlide className={`!w-auto mr-4 last:mr-0`} key={JSON.stringify(el)}>
                          {showOption(el)}
                        </SwiperSlide>
                      ),
                    )}
                  </Swiper>
                )}
                <Button variant={'white'} onClick={() => setShowAdditionalOpt((p) => !p)}>
                  <div className="flex gap-2 transition-all items-center">
                    {showAdditionalOpt ? 'Свернуть' : 'Показать все'}
                    <ChevronDownIcon
                      style={{ transform: `rotate(${showAdditionalOpt ? 180 : 0}deg)` }}
                    />
                  </div>
                </Button>
              </div>
              <div className="lg:text-[22px] text-[19px] mt-5 lg:mb-1 flex w-full justify-end text-[#FF4545] gap-2">
                <div className="font-Istok">Цена окна:</div>
                <div className="font-Istok">
                  {isLoading ? (
                    <Spinner />
                  ) : prices?.rooms?.[itemId]?.total && priceFOpts ? (
                    prices?.rooms?.[itemId]?.total - priceFOpts
                  ) : (
                    prices?.rooms?.[itemId]?.total
                  )}{' '}
                  ₽
                </div>
              </div>
              <div className="font-normal text-base text-end leading-none">
                с учетом выбранных опций
              </div>
            </div>
          </div>
        </div>

        {orderState.balconiesWorks[index] && type === 'balcony' && (
          <div className="flex mt-8 max-lg:flex-col">
            {showFinishing ? (
              <>
                <div className="mb-[10px] font-Istok lg:text-base text-[21px] max-lg:font-bold lg:hidden">
                  Отделка лоджии
                </div>
                <div
                  className="lg:w-[45%] aspect-square h-fit max-lg:mb-5 bg-contain bg-no-repeat"
                  style={{
                    backgroundImage: 'url(/images/Otdelka.webp)',
                    backgroundPosition: 'lg:bg-left-top',
                  }}
                />
              </>
            ) : (
              <>
                <div className="w-[15%] mr-4">
                  <div className="mb-[10px] font-Istok text-base">Отделка лоджии</div>

                  <div
                    className="w-full aspect-square rounded-lg bg-no-repeat bg-contain"
                    style={{ backgroundImage: 'url(/images/mock/balcony-finish.jpg)' }}
                  />
                </div>
                {data?.image_mask_path && (
                  <div className="h-fit mt-auto w-[9.4%]">
                    <div className="font-Istok font-bold text-sm">Расположение</div>
                    <Cell className="w-full aspect-square">
                      <div
                        className="w-full aspect-square relative"
                        style={{
                          background: `url(${data.image_mask_path})`,
                          backgroundSize: 'contain',
                        }}
                      />
                    </Cell>
                  </div>
                )}
              </>
            )}
            <div
              className={clsx(
                'ml-auto rounded-2xl flex',
                showFinishing ? 'lg:w-1/2' : 'w-[68.5%] p-4',
              )}
              style={
                showFinishing
                  ? {}
                  : {
                      background: 'radial-gradient(74.5% 74.5% at 50% 50%, #FFF 0%, #DEDEDE 100%)',
                    }
              }
            >
              {showFinishing ? (
                <div className="w-full">
                  <div className="font-Istok lg:text-[22px] text-[19px] mb-4">
                    Что входит в отделку лоджии
                  </div>
                  <div className="overflow-auto !max-h-[50vh] mb-1 pr-1">
                    {thisOptions.map((el: any, i: number) =>
                      el.selected !== undefined && el.selected !== -1 && el.is_finishing ? (
                        <Item
                          data={el}
                          key={JSON.stringify(el)}
                          onClick={(e: React.MouseEvent<HTMLElement>) =>
                            handleOptionClick(el.slug, e)
                          }
                          type={typeKey}
                          index={index}
                          i={i}
                        />
                      ) : null,
                    )}
                  </div>
                  <div className="rounded-lg p-4 bg-[#3E3E40] text-white">
                    <div className="font-Istok text-base">Дополнительные опции отделки лоджии</div>

                    {showAdditionalOptB ? (
                      <div className={'flex flex-col w-full lg:gap-4 gap-2 my-4'}>
                        {(thisOptionsUnselected
                          ? thisOptionsUnselected?.filter((el: any) => el.is_finishing)
                          : []
                        ).map((el: any) => drawAddOption(el))}
                      </div>
                    ) : (
                      <div className="w-full overflow-hidden">
                        <Swiper
                          slidesPerView={'auto'}
                          className={
                            'cursor-grab active:cursor-grabbing select-none relative !pr-4 my-4'
                          }
                          modules={[FreeMode, Mousewheel]}
                          mousewheel={{ forceToAxis: true }}
                          freeMode={{ enabled: true, sticky: false }}
                        >
                          {(thisOptionsUnselected
                            ? thisOptionsUnselected?.filter((el: any) => el.is_finishing)
                            : []
                          ).map((el: any, i: number) => (
                            <SwiperSlide
                              className={`!w-auto mr-4 last:mr-0 `}
                              key={JSON.stringify(el) + i}
                            >
                              {drawAddOption(el)}
                            </SwiperSlide>
                          ))}
                        </Swiper>
                      </div>
                    )}
                    <Button variant={'white'} onClick={() => setShowAdditionalOptB((p) => !p)}>
                      <div className="flex gap-2 transition-all items-center">
                        {showAdditionalOptB ? 'Свернуть' : 'Показать все'}
                        <ChevronDownIcon
                          style={{ transform: `rotate(${showAdditionalOptB ? 180 : 0}deg)` }}
                        />
                      </div>
                    </Button>
                  </div>

                  <div className="text-[22px] mt-5 lg:mb-1 flex w-full justify-end text-[#FF4545] gap-2 max-lg:pr-5">
                    <div className="font-Istok">Цена отделки лоджии: </div>
                    <div className="font-Istok">{isLoading ? <Spinner /> : priceFOpts} ₽</div>
                  </div>
                  <div className="font-normal text-base text-end max-lg:pr-5">
                    с учетом выбранных опций
                  </div>
                </div>
              ) : (
                <>
                  <div className="font-bold flex flex-col h-full">
                    {/* <div className="font-Istok text-[18px]">Цена отделки</div> */}
                    {/* <div className="font-Istok text-[32px] leading-none">- ₽</div> */}
                    <Button
                      variant="white"
                      className="mt-auto !px-4"
                      onClick={() => setShowFinishing(true)}
                    >
                      Включить&nbsp;в&nbsp;заказ
                    </Button>
                  </div>
                  <div className="w-[70%] ml-auto">
                    <div className="rounded-xl bg-white p-5 h-fit">
                      <div className="font-Istok text-[22px]">Что входит в заказ</div>
                      <div className="flex flex-wrap w-full gap-4 my-4">
                        {thisOptions
                          ?.filter((el: any) => el.is_finishing && el.enabled)
                          .map((el: any) => (
                            <Option data={el} key={JSON.stringify(el)} />
                          ))}
                      </div>
                    </div>
                  </div>
                </>
              )}
            </div>
          </div>
        )}
      </div>

      <Options
        open={isOptionsOpen >= 0 && isOptionsOpen < 5}
        dataKey={key}
        data={{ index, indexAbs, typeKey, isOptionsOpen, setIsOptionsOpen, isLoading }}
      />

      <OptionsBalcony
        open={isOptionsOpen >= 5}
        dataKey={key}
        data={{ index, indexAbs, typeKey, isOptionsOpen, setIsOptionsOpen, isLoading }}
      />

      {showOpenModal && (
        <Modal
          className="md:w-1/4 sm:w-1/2 w-full relative !bg-[#F4F4F4] overflow-auto flex flex-col gap-3 text-black"
          onClose={() => setShowOpenModal(false)}
        >
          <h2 className="text-[20px]">Тип открывания</h2>
          <p className="text-base font-normal">
            Все открывающиеся створки на изображениях имеют условные обозначения:
          </p>
          <div
            className="w-full bg-contain aspect-[2.1] bg-no-repeat"
            style={{ backgroundImage: 'url(/images/tips.png)' }}
          />
        </Modal>
      )}
    </>
  );
}
