import clsx from 'clsx';
import React from 'react';

interface Props {
  checked: boolean;
  disabled?: boolean;
  className?: string;
  onChange?: (checked: boolean) => void;
  onClick?: (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => void;
}

export const Radio = ({ checked, className, disabled = false, onChange, ...otherProps }: Props) => (
  <div
    onClick={() => onChange && !disabled && onChange(!checked)}
    className={clsx('select-none', !disabled && 'cursor-pointer', className)}
    {...otherProps}
  >
    {checked ? (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="20"
        height="20"
        viewBox="0 0 20 20"
        fill="#fff"
      >
        <path
          d="M18.333 9.99967C18.333 14.602 14.602 18.333 9.99967 18.333C5.3973 18.333 1.66634 14.602 1.66634 9.99967C1.66634 5.3973 5.3973 1.66634 9.99967 1.66634C14.602 1.66634 18.333 5.3973 18.333 9.99967Z"
          stroke="#FF4545"
          strokeWidth="1.66667"
        />
        <circle cx="10.0003" cy="10.0003" r="3.33333" fill="#FF4545" />
      </svg>
    ) : (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="20"
        height="20"
        viewBox="0 0 20 20"
        fill="#fff"
      >
        <path
          d="M18.333 9.99967C18.333 14.602 14.602 18.333 9.99967 18.333C5.3973 18.333 1.66634 14.602 1.66634 9.99967C1.66634 5.3973 5.3973 1.66634 9.99967 1.66634C14.602 1.66634 18.333 5.3973 18.333 9.99967Z"
          stroke="#666"
          strokeWidth="1.66667"
        />
        <defs>
          <radialGradient
            id="paint0_radial_1441_23830"
            cx="0"
            cy="0"
            r="1"
            gradientUnits="userSpaceOnUse"
            gradientTransform="translate(9.99967 9.99967) rotate(90) scale(9.16667)"
          >
            <stop stopColor="#2F2F2F" />
            <stop offset="1" stopColor="#1A1A1A" />
          </radialGradient>
        </defs>
      </svg>
    )}
  </div>
);
