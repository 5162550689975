import React from 'react';
import { Block } from '../Block';
import clsx from 'clsx';

interface Props {
  children?: React.ReactNode;
  header?: React.ReactNode;
  title?: React.ReactNode;
  step?: number;
  className?: string;
  classNameContainer?: string;
  style?: any;
}

export const Cell = ({
  className,
  children,
  header,
  title,
  step,
  style,
  classNameContainer,
}: Props) => (
  <Block className={clsx('lg:mb-10 mb-6 font-Istok font-bold', className)} style={style}>
    {header ? header : null}
    {step ? <div className="text-[#FF4545] lg:text-[22px] text-[17px]">Шаг {step}</div> : null}
    {title ? <div className="lg:text-[30px] text-[24px] mt-1 lg:mb-6 mb-3">{title}</div> : null}
    <div className={classNameContainer}>{children}</div>
  </Block>
);
