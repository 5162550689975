import clsx from 'clsx';
import React from 'react';

export const Price = ({
  name,
  value,
  className,
}: {
  name: React.ReactNode;
  value: React.ReactNode;
  className?: string;
}) => (
  <div
    className={clsx(
      'flex justify-between items-center lg:text-[22px] sm:text-[19px] text-[17px]',
      className,
    )}
  >
    <div className="font-Istok lg:mb-1 mb-2">{name}</div>
    <div className="font-Istok">{value}</div>
  </div>
);
