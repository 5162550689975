import React from 'react';
import { ChevronRightIcon } from '../Icons/ChevronRight';
import clsx from 'clsx';

interface NavigationButtonProps {
  direction: 'prev' | 'next';
  className?: string;
  onClick?: () => void;
}

const NavigationButton: React.FC<NavigationButtonProps> = ({ direction, className, onClick }) => (
  <button
    className={clsx(
      `lg:w-8 w-6 aspect-square flex items-center justify-center bg-white rounded-[5px] z-[99] !absolute lg:!top-[28px] !top-3 cursor-pointer`,
      className,
    )}
    onClick={onClick}
    style={{ transform: `rotate(${direction === 'prev' ? 180 : 0}deg)` }}
  >
    <ChevronRightIcon color="#FF4545" />
  </button>
);

export default NavigationButton;
