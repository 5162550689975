import clsx from 'clsx';
import React, { useEffect, useRef, useState } from 'react';
import { sendMessageFromIframe } from '../../utils/helpers';
import { createPortal } from 'react-dom';

interface Props {
  children?: React.ReactNode;
  className?: string;
  classNameModal?: string;
  onClose?: () => void;
  hideCloseButton?: boolean;
}

export const Modal = ({
  children,
  className,
  classNameModal,
  onClose,
  hideCloseButton = false,
}: Props) => {
  const modalRef = useRef<HTMLDivElement | null>(null);
  const rootEl = document.getElementById('root');

  const closeModal = () => {
    sessionStorage.removeItem('modalTop');
    document.body.style.overflow = 'auto';
    if (rootEl) rootEl.style.overflow = 'auto';

    if (onClose) onClose();
  };

  const handleClickOutside = (event: MouseEvent) => {
    if (modalRef.current && !modalRef.current.contains(event.target as Node)) {
      closeModal();
    }
  };

  const [modalTop, setModalTop] = useState(0);

  useEffect(() => {
    const mt = sessionStorage.getItem('modalTop');
    if (mt) setModalTop(+mt);
    console.log('marginTop modal', mt);

    document.addEventListener('mousedown', handleClickOutside);

    setTimeout(() => {
      // const label = document.getElementById('options-label');
      // if (label) {
      // if (rootEl && mt && +mt) {
      //   console.log('rootEl scroll to ', mt);
      //   rootEl.scrollTo(0, +mt);
      // } else {
      // console.log('scroll into view label');
      // label.scrollIntoView({ block: 'start' });
      // }
      // } else
      if (modalRef?.current) {
        console.log('scroll into view modalRef');
        modalRef.current.scrollIntoView({ block: 'center' });
      }
    }, 99);

    return () => {
      sessionStorage.removeItem('modalTop');
      document.body.style.overflow = 'auto';
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  return rootEl
    ? createPortal(
        <div
          className={clsx(
            'absolute top-0 left-0 right-0 bottom-0 flex justify-center z-50 overflow-auto',
            classNameModal || (!modalTop && 'lg:items-center'),
          )}
          id="imodalcont"
          style={
            modalTop
              ? { paddingTop: modalTop, background: 'rgba(32, 32, 32, 0.45)' }
              : { background: 'rgba(32, 32, 32, 0.45)' }
          }
        >
          <div
            id="imodal"
            ref={modalRef}
            className={clsx(
              'bg-white lg:p-5 lg:pb-3 lg:pt-4 p-4 rounded-2xl relative h-fit lg:max-h-[98dvh] overflow-auto',
              className,
            )}
          >
            {!hideCloseButton && (
              <button onClick={closeModal} className="absolute lg:top-6 lg:right-6 top-4 right-4">
                ✕
              </button>
            )}
            {children}
          </div>
        </div>,
        rootEl,
      )
    : null;
};
