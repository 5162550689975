import React from 'react';
import { SVGProps } from './ArrowRight';

export const BuildingIcon = (props: SVGProps) => (
  <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="none" {...props}>
    <g fill="#3E3E40">
      <path
        fillRule="evenodd"
        d="M14.763.075A.5.5 0 0 1 15 .5v15a.5.5 0 0 1-.5.5h-3a.5.5 0 0 1-.5-.5V14h-1v1.5a.5.5 0 0 1-.5.5h-9a.5.5 0 0 1-.5-.5V10a.5.5 0 0 1 .342-.474L6 7.64V4.5a.5.5 0 0 1 .276-.447l8-4a.5.5 0 0 1 .487.022ZM6 8.694 1 10.36V15h5V8.694ZM7 15h2v-1.5a.5.5 0 0 1 .5-.5h2a.5.5 0 0 1 .5.5V15h2V1.309l-7 3.5V15Z"
        clipRule="evenodd"
      />
      <path d="M2 11h1v1H2v-1ZM4 11h1v1H4v-1ZM2 13h1v1H2v-1ZM4 13h1v1H4v-1ZM8 9h1v1H8V9ZM10 9h1v1h-1V9ZM8 11h1v1H8v-1ZM10 11h1v1h-1v-1ZM12 9h1v1h-1V9ZM12 11h1v1h-1v-1ZM8 7h1v1H8V7ZM10 7h1v1h-1V7ZM12 7h1v1h-1V7ZM8 5h1v1H8V5ZM10 5h1v1h-1V5ZM12 5h1v1h-1V5ZM12 3h1v1h-1V3Z" />
    </g>
  </svg>
);
