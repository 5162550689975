export const mockOptionsData: any = [
  {
    name: 'Оконная система',
    toAll: true,
    data: [
      {
        src: '/images/mock/systems1.jpg',
        name: 'СТАНДАРТ 60',
        fullName: 'СТАНДАРТ 60 | Экономное остекление',
        desc: 'Идеальный по сочетанию стоимости и качества вариант для остекления новых объектов или реконструкции жилых зданий.',
        camera: '5 камер',
        profileDepth: '70 мм',
        profileHeight: '112 мм',
        glass: '40 мм',
        furniture: 'SoftOPEN™ Base',
        heat: '0,78м²·°С/Вт',
        sound: '≈40 Rw(дБ)',
        price: 10283,
      },
      {
        src: '/images/mock/systems1.jpg',
        name: 'СТАНДАРТ 70',
        fullName: 'СТАНДАРТ 70 | Универсальное остекление',
        desc: 'Универсальная система для создания окон любой сложности и конфигурации, обеспечивающая высокие тепло- и звукоизоляционные характеристики.',
        camera: '5 камер',
        profileDepth: '70 мм',
        profileHeight: '112 мм',
        glass: '40 мм',
        furniture: 'SoftOPEN™ Comfort',
        heat: '0,82м²·°С/Вт',
        sound: '≈42 Rw(дБ)',
        price: 11500,
      },
      {
        src: '/images/mock/systems1.jpg',
        name: 'REHAU BLITZ',
        fullName: 'REHAU BLITZ | Профиль с улучшенными теплоизоляционными характеристиками',
        desc: 'Инновационная система для оконных конструкций, обеспечивающая высокую теплоизоляцию и энергосбережение.',
        camera: '6 камер',
        profileDepth: '70 мм',
        profileHeight: '120 мм',
        glass: '40 мм',
        furniture: 'ProComfort™ Comfort',
        heat: '0,76м²·°С/Вт',
        sound: '≈44 Rw(дБ)',
        price: 12500,
      },
      {
        src: '/images/mock/systems1.jpg',
        name: 'REHAU GRAZIO',
        fullName: 'REHAU GRAZIO | Элегантное остекление с повышенной шумоизоляцией',
        desc: 'Элегантная система с повышенной шумоизоляцией, обеспечивающая комфорт и тишину в помещении.',
        camera: '5 камер',
        profileDepth: '70 мм',
        profileHeight: '112 мм',
        glass: '40 мм',
        furniture: 'ProComfort™ Comfort',
        heat: '0,80м²·°С/Вт',
        sound: '≈46 Rw(дБ)',
        price: 13500,
      },
    ],
  },
  {
    name: 'Тип открывания',
    toAll: false,
    data: [
      {
        src: '/images/mock/open1.jpg',
        name: 'Оконная система 1',
        price: 10283,
      },
      {
        src: '/images/mock/open1.jpg',
        name: 'Оконная система 2',
        price: 11000,
      },
      {
        src: '/images/mock/open1.jpg',
        name: 'Оконная система 3',
        price: 12000,
      },
      {
        src: '/images/mock/open1.jpg',
        name: 'Оконная система 4',
        price: 13000,
      },
    ],
  },
  {
    name: 'Цветные окна',
    toAll: false,
    code: 'lamination',
    data: [
      { name: 'Золотой дуб', src: '/images/mock/cover1.jpg', price: 1183 },
      { name: 'Антрацитово-серый гладкий', src: '/images/mock/cover2.png', price: 1283 },
      { name: 'Коричневый дуб', src: '/images/mock/cover3.png', price: 1383 },
      { name: 'Угольный серый', src: '/images/mock/cover4.png', price: 1483 },
      { name: 'Сапели', src: '/images/mock/cover5.png', price: 1583 },
    ],
  },
  {
    name: 'Подоконники',
    code: 'sill',
    toAll: true,
    data: [
      {
        src: '/images/mock/pod1.png',
        srcBig: '/images/mock/pod1big.jpg',
        name: 'Подоконник ПВХ белый',
        price: 3998,
      },
      {
        src: '/images/mock/pod2.png',
        srcBig: '/images/mock/pod1big.jpg',
        name: 'Подоконник ПВХ жёлтый',
        price: 3990,
      },
      {
        src: '/images/mock/pod1.png',
        srcBig: '/images/mock/pod1big.jpg',
        name: 'Подоконник ПВХ серый',
        price: 3991,
      },
      {
        src: '/images/mock/pod2.png',
        srcBig: '/images/mock/pod1big.jpg',
        name: 'Подоконник ПВХ чёрный',
        price: 3994,
      },
      {
        src: '/images/mock/pod1.png',
        srcBig: '/images/mock/pod1big.jpg',
        name: 'Подоконник ПВХ графит',
        price: 3999,
      },
      {
        src: '/images/mock/pod1.png',
        srcBig: '/images/mock/pod1big.jpg',
        name: 'Подоконник ПВХ белый',
        price: 3998,
      },
    ],
  },
  {
    name: 'Ручки',
    code: 'handle',
    toAll: true,
    data: [
      {
        src: '/images/mock/ruchki1.png',
        srcBig: '/images/mock/pod1big.jpg',
        name: 'Rhapsody белая (Австрия)',
        price: 3988,
      },
      {
        src: '/images/mock/ruchki2.png',
        srcBig: '/images/mock/pod1big.jpg',
        name: 'Rhapsody бронза (Австрия)',
        price: 1444,
      },
      {
        src: '/images/mock/ruchki3.png',
        srcBig: '/images/mock/pod1big.jpg',
        name: 'Rhapsody белая с ключом',
        price: 2323,
      },
      {
        src: '/images/mock/ruchki4.png',
        srcBig: '/images/mock/pod1big.jpg',
        name: 'Rhapsody светлокоричневая (Австрия)',
        price: 3333,
      },
    ],
  },
  {
    name: 'Пороги с наружной стороны',
    code: 'extdoorsill',
    toAll: false,
    data: [],
  },
  {
    name: 'Подоконники с наружной стороны',
    code: 'extwindsill',
    toAll: false,
    data: [],
  },
  {
    name: 'Отделка пола',
    code: 'floorfinish',
    toAll: false,
    data: [],
  },
  {
    name: 'Отделка стен (панели, вагонка)',
    code: 'loggdecor',
    toAll: false,
    data: [],
  },
  {
    name: 'Отделка стен, потолка, парапета',
    code: 'loggdecor2',
    toAll: false,
    data: [],
  },
];
