import React from 'react';
import { SVGProps } from './ArrowRight';

export const DoneIcon = (props: SVGProps) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    {...props}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M10.384 16C10.0673 16.0016 9.75071 15.8838 9.50665 15.6413L5.36674 11.5197C4.8819 11.0349 4.87696 10.2478 5.35685 9.75966C5.83674 9.26983 6.62007 9.26485 7.10655 9.74804L10.3658 12.9929L16.8821 6.37072C17.3636 5.88089 18.147 5.87591 18.6318 6.35909C19.1183 6.84394 19.1232 7.63265 18.6434 8.11915L11.2564 15.6297C11.0156 15.8755 10.7006 15.9983 10.384 16Z"
      fill="#05E300"
    />
  </svg>
);
