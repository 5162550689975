import React from 'react';

import Page404 from './modules/Page404/Page404';
import Start from './modules/Start';
import CalcPlan from './modules/Calc/Plan';
import CalcOpenPlan from './modules/Calc/OpenPlan';

const routes = [
  { path: '/', element: <Start /> },
  { path: '/calc/plan', element: <CalcPlan /> },
  { path: '/calc/open-plan', element: <CalcOpenPlan /> },
  { path: '*', element: <Page404 /> },
];

export default routes;
