import React, { useEffect, useState } from 'react';

import { toast } from 'react-toastify';

import { useDispatch, useSelector } from 'react-redux';

import Configurator from '../../Configurator';
import { Block } from '../../../components/Block';
import Button from '../../../components/Button';
import {
  RootState,
  setBalconies,
  setDiscount,
  setHouseType,
  setHouseTypes,
  setOrder,
  setPrices,
  setProfilesInfo,
  setRooms,
  setWindows,
} from '../../../redux/store';
import { checkIfBalcony, handleError, handleResize } from '../../../utils/helpers';
import { ArrowRightIcon } from '../../../components/Icons/ArrowRight';
import { SelectorBalconies } from '../../../components/Selectors/Balconies';
import { SelectorWindows } from '../../../components/Selectors/Windows';
import {
  fetchPlans,
  fetchPlansDefault,
  getOptionsCustom,
  getProfilesInfo,
} from '../../../utils/httpServices/global';
import { Order } from '../../../components/Order';
import ModalFloor from '../../Configurator/ModalFloor';

const Calc = () => {
  const dispatch = useDispatch();
  const [type, setType] = useState('');
  const windows = useSelector((state: RootState) => state.global.windows);
  const balconies = useSelector((state: RootState) => state.global.balconies);
  const houseTypes = useSelector((state: RootState) => state.global.houseTypes);
  const rooms = useSelector((state: RootState) => state.global.rooms);
  const order = useSelector((state: RootState) => state.global.order);
  const isShowOrder = useSelector((state: RootState) => state.global.isShowOrder);

  const [step, setStep] = useState(1);
  const [pricesObj, setPricesObj] = useState<any>(null);
  const [isModalFloorOpen, setIsModalFloorOpen] = useState(false);

  const isNothingSelected =
    !Object.values(windows).some(Boolean) && !Object.values(balconies).some(Boolean);

  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    setIsLoading(true);
    getProfilesInfo()
      .then((res) => {
        if (res) dispatch(setProfilesInfo(res));
      })
      .finally(() => {
        setIsLoading(false);
      });

    dispatch(setDiscount(false));
    dispatch(setPrices({}));
    dispatch(setHouseTypes(null));
    dispatch(setHouseType(''));
    dispatch(setRooms(null));
    const dataSS = sessionStorage.getItem('address');
    const data = dataSS ? JSON.parse(dataSS) : null;
    const nameSS = sessionStorage.getItem('name');
    const isDefaultSS = sessionStorage.getItem('isDefault');
    if (data) {
      const fetchPl = isDefaultSS ? fetchPlansDefault : fetchPlans;
      setIsLoading(true);

      fetchPl(data)
        .then((res) => {
          if (nameSS || isDefaultSS) {
            dispatch(setHouseTypes([res]));
          } else if (res?.windows_types) {
            dispatch(setHouseTypes(res?.windows_types));
          } else if (res?.response?.data) {
            dispatch(setHouseTypes(res?.response?.data));
          }
        })
        .catch((error) => {
          handleError(error);
        })
        .finally(() => {
          setIsLoading(false);
        });
    }
  }, []);

  useEffect(() => {
    dispatch(setHouseType(type));
    dispatch(setBalconies({}));
    dispatch(setWindows({}));
    dispatch(setDiscount(false));
    dispatch(setPrices({}));
  }, [dispatch, type]);

  useEffect(() => {
    const idsW = Object.entries(windows).flatMap(([key, val]) => Array(val).fill(key));
    const idsB = Object.entries(balconies).flatMap(([key, val]) => Array(val).fill(key));

    const ids = [...idsW, ...idsB].flatMap((el: any) => +el);

    if (!ids.length) return;

    const resultObject = {
      apertures: Object.entries(
        ids.reduce((acc: any, curr: any) => {
          acc[curr] = acc[curr] ? acc[curr] + 1 : 1;
          return acc;
        }, {}),
      ).map(([id, count]) => ({ id: parseInt(id), count })),
    };

    setPricesObj(resultObject);
  }, [windows, balconies]);

  useEffect(() => {
    if (houseTypes && Object.keys(houseTypes).length === 1) {
      setType(Object.keys(houseTypes)?.[0]);
      dispatch(setHouseType(Object.keys(houseTypes)?.[0]));
    }
  }, [houseTypes]);

  const onFinish = () => {
    if (isNothingSelected) {
      toast('Выберите окна и/или балкон/лоджию, которые планируется поменять', {
        type: 'warning',
      });
      return;
    }

    setIsLoading(true);
    setIsModalFloorOpen(true);

    getOptionsCustom(pricesObj)
      .then((res) => {
        if (!Array.isArray(res)) {
          toast(res?.error || res, { type: 'error' });
          return;
        }
        const result = res
          ? res.map((el: any, i: number) => ({
              ...el,
              name: `Окно ${i + 1}${checkIfBalcony(el.name) ? ' (балкон/лоджия)' : ''}`,
            }))
          : null;
        const pl = { planData: result, planId: 0 };
        const newOrderData = order ? { ...order, ...pl } : { ...pl };
        dispatch(setOrder(newOrderData));
        dispatch(setRooms(result));
        setTimeout(() => {
          setStep(2);
        }, 99);
      })
      .catch((error) => handleError(error))
      .finally(() => setIsLoading(false));
  };

  useEffect(() => {
    handleResize();
  }, [windows, balconies, houseTypes, rooms, order, isShowOrder]);

  if (isShowOrder) return <Order />;

  return (
    <>
      <ModalFloor
        isModalOpen={isModalFloorOpen}
        setIsModalOpen={setIsModalFloorOpen}
        isLoading={isLoading}
      />
      {step === 1 ? (
        <div className="lg:p-8 p-4 font-Istok font-bold flex flex-col lg:gap-8 gap-4">
          {houseTypes && Object.keys(houseTypes).length > 1 ? (
            <Block>
              <div className="lg:text-[32px] text-[23px] mb-6">
                Для точного расчёта заказа выберите тип вашего дома
              </div>
              <div className="flex flex-wrap justify-between gap-4">
                {houseTypes &&
                  Object.entries(houseTypes).map((el) => (
                    <Button
                      className="lg:block flex flex-auto"
                      key={el[0]}
                      variant="white"
                      selected={el[0] === type}
                      onClick={() => setType(el[0])}
                      onKeyDown={(e) => {
                        if (e.key === 'Enter' || e.key === ' ') {
                          setType(el[0]);
                        }
                      }}
                    >
                      {el[1].name}
                    </Button>
                  ))}
              </div>
            </Block>
          ) : null}
          {!!type && houseTypes && (
            <React.Fragment key={type}>
              <SelectorWindows data={houseTypes?.[type]?.windows} />
              <SelectorBalconies data={houseTypes?.[type]?.loggia} />

              <Button
                variant="red"
                className="mx-auto flex gap-2 items-center font-Istok font-bold !text-[18px] w-fit lg:!px-8 lg:!py-[10px] !px-6 !py-4"
                disabled={isNothingSelected}
                onClick={onFinish}
              >
                Перейти к заказу <ArrowRightIcon />
              </Button>
            </React.Fragment>
          )}
        </div>
      ) : (
        <Configurator rooms={rooms} />
      )}
    </>
  );
};

export default Calc;
