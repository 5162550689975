import axios from 'axios';

import { URL_API } from '../consts';

import { checkAndRefreshToken } from './token';

const createAxiosInstance = () => {
  const axiosInstance = axios.create({
    baseURL: URL_API,
    headers: { 'Content-Type': 'application/json' },
  });

  return axiosInstance;
};

const axiosInstance = createAxiosInstance();

axiosInstance.interceptors.request.use(
  async (config) => {
    // globalController.startRequest();
    await checkAndRefreshToken(); // Проверка и обновление токена
    const tokenDataSS = sessionStorage.getItem('token');
    const tokenData = tokenDataSS ? JSON.parse(tokenDataSS) : '';
    if (tokenData?.token) {
      config.headers.Authorization = `Bearer ${tokenData.token}`;
    }

    return config;
  },
  (error) => {
    // globalController.stopRequest();
    return Promise.reject(error);
  },
);

axiosInstance.interceptors.response.use(
  (response) => {
    // globalController.stopRequest();
    return response;
  },
  (error) => {
    // globalController.stopRequest();
    return Promise.reject(error);
  },
);

export default axiosInstance;
