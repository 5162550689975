import React, { useEffect, useState } from 'react';
import { Modal } from '../../components/Modal';
import { Input } from '../../components/Input';
import { MAX_FLOOR } from '../../utils/consts';
import Button from '../../components/Button';
import Spinner from '../../components/Spinner';
import { useDispatch, useSelector } from 'react-redux';
import { RootState, setOrderFloor } from '../../redux/store';

interface Props {
  isLoading?: boolean;
  isModalOpen: boolean;
  setIsModalOpen: (value: boolean) => void;
}

export default function ModalFloor({ isModalOpen, setIsModalOpen, isLoading }: Props) {
  const dispatch = useDispatch();
  const order = useSelector((state: RootState) => state.global.order);
  const [floor, setFloor] = useState(order?.floor || 1);

  useEffect(() => {
    if (order?.floor) setFloor(order.floor);

    const handleKeyDown = (e: KeyboardEvent) => {
      if (e.key === 'Enter') {
        onClose();
      }
    };

    window.addEventListener('keydown', handleKeyDown);
    return () => window.removeEventListener('keydown', handleKeyDown);
  }, []);

  const onClose = () => {
    if (+floor > 0 && +floor <= MAX_FLOOR) {
      setIsModalOpen(false);
      dispatch(setOrderFloor(+floor));
    }
  };

  if (!isModalOpen) return null;

  return (
    <Modal className="max-w-[364px]" classNameModal="" onClose={onClose}>
      <div className="text-[20px] mb-4">
        Для точного расчета стоимости остекления, укажите этаж, на котором находится Ваша квартира
      </div>
      <div className="flex gap-4 items-center h-[52px] w-full">
        <Input
          value={floor}
          onChange={(e) => {
            const val = e.target.value;
            setFloor(val);
          }}
          className="h-full w-1/2 !min-w-[5rem]"
        />
        <Button
          variant="red"
          className="h-full w-1/2 flex items-center gap-4 ml-auto"
          disabled={!floor || isLoading || !order || +floor < 1 || +floor > MAX_FLOOR}
          onClick={onClose}
        >
          {isLoading && <Spinner color="white" />}
          Подтвердить
        </Button>
      </div>
    </Modal>
  );
}
