import React from 'react';
import { SVGProps } from './ArrowRight';

export const QuestionIcon = ({ color = '#777', ...otherProps }: SVGProps) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="12"
    height="12"
    viewBox="0 0 12 12"
    fill="none"
    style={{ minWidth: 12, minHeight: 12 }}
    {...otherProps}
  >
    <path
      d="M6 11.25C3.10051 11.25 0.75 8.8995 0.75 6C0.75 3.10051 3.10051 0.75 6 0.75C8.8995 0.75 11.25 3.10051 11.25 6C11.25 8.8995 8.8995 11.25 6 11.25ZM6 12C9.31371 12 12 9.31371 12 6C12 2.68629 9.31371 0 6 0C2.68629 0 0 2.68629 0 6C0 9.31371 2.68629 12 6 12Z"
      fill={color}
    />
    <path
      d="M3.94133 4.33961C3.93564 4.44177 4.01993 4.52454 4.12225 4.52454H4.74062C4.84417 4.52454 4.92651 4.4401 4.94042 4.33748C5.00714 3.84539 5.34488 3.48669 5.94691 3.48669C6.46107 3.48669 6.93239 3.74377 6.93239 4.36267C6.93239 4.83875 6.6515 5.05774 6.20876 5.39099C5.70412 5.75757 5.30421 6.18604 5.33278 6.8811L5.33501 7.04371C5.33641 7.14625 5.41994 7.22864 5.52249 7.22864H6.13075C6.23431 7.22864 6.31825 7.14469 6.31825 7.04114V6.96204C6.31825 6.42407 6.52296 6.26697 7.07521 5.84802C7.53224 5.50049 8.00832 5.11487 8.00832 4.30554C8.00832 3.17249 7.05141 2.625 6.00404 2.625C5.05373 2.625 4.01223 3.06796 3.94133 4.33961ZM5.10902 8.66162C5.10902 9.06152 5.42799 9.35669 5.86598 9.35669C6.32301 9.35669 6.63722 9.06152 6.63722 8.66162C6.63722 8.24744 6.32301 7.95703 5.86598 7.95703C5.42799 7.95703 5.10902 8.24744 5.10902 8.66162Z"
      fill={color}
    />
  </svg>
);
