import React from 'react';

interface Props {
  rounded?: boolean;
  checked: boolean;
  className?: string;
  onChange: (checked: boolean) => void;
}

export const Checkbox = ({ checked, onChange, className, rounded }: Props) => (
  <svg
    className={className}
    xmlns="http://www.w3.org/2000/svg"
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    onClick={() => onChange(!checked)}
    onKeyDown={(e) => {
      if (e.key === 'Enter' || e.key === ' ') onChange(!checked);
    }}
    style={{ cursor: 'pointer' }}
  >
    {checked ? (
      rounded ? (
        <>
          <rect
            x="2.75"
            y="2.75"
            width="18.5"
            height="18.5"
            rx="5.25"
            fill="#F4F4F4"
            stroke="#FF4545"
            strokeWidth="1.5"
          />
          <path
            d="M8 11.2308L11.3158 15L17 8"
            stroke="#FF4545"
            strokeWidth="1.8"
            strokeLinecap="round"
          />
        </>
      ) : (
        <>
          <path
            fill="#FF4545"
            d="M16.28 9.22a.748.748 0 0 1 0 1.06l-5.25 5.25a.747.747 0 0 1-1.06 0l-2.25-2.25a.75.75 0 1 1 1.06-1.06l1.72 1.72 4.72-4.72a.749.749 0 0 1 1.06 0ZM21 4.5v15a1.5 1.5 0 0 1-1.5 1.5h-15A1.5 1.5 0 0 1 3 19.5v-15A1.5 1.5 0 0 1 4.5 3h15A1.5 1.5 0 0 1 21 4.5Zm-1.5 15v-15h-15v15h15Z"
          />
          <path
            stroke="#FF4545"
            strokeWidth=".6"
            d="M16.28 9.22a.748.748 0 0 1 0 1.06l-5.25 5.25a.747.747 0 0 1-1.06 0l-2.25-2.25a.75.75 0 1 1 1.06-1.06l1.72 1.72 4.72-4.72a.749.749 0 0 1 1.06 0ZM21 4.5v15a1.5 1.5 0 0 1-1.5 1.5h-15A1.5 1.5 0 0 1 3 19.5v-15A1.5 1.5 0 0 1 4.5 3h15A1.5 1.5 0 0 1 21 4.5Zm-1.5 15v-15h-15v15h15Z"
          />
        </>
      )
    ) : rounded ? (
      <rect
        x="2.75"
        y="2.75"
        width="18.5"
        height="18.5"
        rx="5.25"
        fill="#F4F4F4"
        // stroke="#FF4545"
        stroke="#3E3E40"
        strokeWidth="1.5"
      />
    ) : (
      <path
        d="M21 4.5V19.5C21 19.8978 20.842 20.2794 20.5607 20.5607C20.2794 20.842 19.8978 21 19.5 21H4.5C4.10218 21 3.72064 20.842 3.43934 20.5607C3.15804 20.2794 3 19.8978 3 19.5V4.5C3 4.10218 3.15804 3.72064 3.43934 3.43934C3.72064 3.15804 4.10218 3 4.5 3H19.5C19.8978 3 20.2794 3.15804 20.5607 3.43934C20.842 3.72064 21 4.10218 21 4.5ZM19.5 19.5V4.5H4.5V19.5H19.5Z"
        fill="#3E3E40"
        stroke="#3E3E40"
        strokeWidth="0.3"
      />
    )}
  </svg>
);
