import React, { useCallback, useEffect, useState } from 'react';
import Button from '../Button';
import { Modal } from '../Modal';
import { CloseIcon } from '../Icons/Close';
import { Formik, Form, ErrorMessage, Field } from 'formik';
import * as Yup from 'yup';
import { useDispatch, useSelector } from 'react-redux';
import { RootState, setDiscount, setOrder, setShowOrder } from '../../redux/store';
import { FormItem } from '../FormItem';
import { Price } from './Price';
import { Divider } from '../Divider';
import clsx from 'clsx';
import Spinner from '../Spinner';
import { sendEmail } from '../../utils/httpServices/global';
import { Input } from '../Input';
import { scrollToTop } from '../../utils/helpers';

const validationSchema = Yup.object().shape({
  name: Yup.string().required('Имя обязательно'),
  phone: Yup.string().required('Телефон обязателен'),
  email: Yup.string().email('Неправильный формат email').required('Email обязателен'),
});

export const TotalPrice = ({ isLoading }: { isLoading?: boolean }) => {
  const dispatch = useDispatch();
  const order = useSelector((state: RootState) => state.global.order);
  const [isModalOpen, setIsModalOpen] = useState(0);
  const prices = useSelector((state: RootState) => state.global.prices);
  // const isDiscount = prices?.discount?.enabled || false;
  const isDiscount = useSelector((state: RootState) => state.global.isDiscount);

  const handleSubmit = useCallback(
    (details: { name: string; phone: string; email: string }) => {
      const newOrderData = order ? { ...order, details } : { details };
      dispatch(setOrder(newOrderData));
      const newData = {
        client_id: 'test',
        source: 'Google',
        campaign: null,
        medium: null,
        content: null,
        request: null,
        ...details,
      };

      const utmSS = sessionStorage.getItem('utm');
      if (utmSS) {
        const utm = JSON.parse(utmSS);
        if (utm._ct_session_id) {
          newData.client_id = utm._ct_session_id;
        }
        newData.source = utm.utm_source;
        newData.campaign = utm.utm_campaign;
        newData.medium = utm.utm_medium;
        newData.content = utm.utm_content;
        newData.request = utm.utm_term;
      }

      sendEmail(order?.planId || 0, newData)
        .then((res) => {
          if (res && !res.error) setIsModalOpen(2);
        })
        .catch(function (error) {
          console.error('send email error:', error);
        });
    },
    [dispatch, order],
  );

  // useEffect(() => {
  //   if (isModalOpen) {
  //     const h2 = document.querySelector('h2');
  //     if (h2) {
  //       console.log('scrollIntoView TotalPrice h2', h2);
  //       h2.scrollIntoView({ block: 'start' });
  //     }
  //   }
  // }, [isModalOpen]);

  return (
    <>
      <div className="ml-auto lg:w-1/2 w-full bg-white rounded-[20px] p-6 font-bold">
        <Price
          name="Окна с выбранными опциями"
          value={
            isLoading ? (
              <Spinner />
            ) : (
              `${prices.total && prices?.delivery ? prices.total - prices.delivery : 0} ₽`
            )
          }
        />
        <Price
          name="Доставка с разгрузкой"
          value={
            isLoading ? (
              <Spinner />
            ) : (
              `${
                prices.total && prices?.delivery && prices.total - prices.delivery > 0
                  ? prices.delivery
                  : 0
              } ₽`
            )
          }
        />

        <Divider className="lg:my-[23px] my-[14px]" />
        <Price
          className="lg:!text-[32px] text-[17px]"
          name="Итоговая сумма"
          value={
            isLoading ? (
              <Spinner />
            ) : (
              <>
                {isDiscount &&
                  prices?.total &&
                  prices?.delivery &&
                  prices.total - prices.delivery > 0 && (
                    <s className="text-[#FF4545]">{prices?.total}</s>
                  )}{' '}
                {isDiscount
                  ? prices.total - prices.delivery > 0
                    ? prices.total - prices.discount.total
                    : 0
                  : prices.total - prices.delivery > 0
                  ? prices.total
                  : 0}{' '}
                ₽
              </>
            )
          }
        />

        <div
          className="w-[92%] bg-[#383838] lg:p-5 p-3 mx-auto mt-6"
          style={{
            borderRadius: '20px 20px 0px 0px',
            boxShadow: '0px -4px 8px 0px rgba(0, 0, 0, 0.06), 0px 0px 4px 0px rgba(0, 0, 0, 0.04)',
          }}
        >
          <div className="flex lg:gap-4 gap-2">
            <div
              className="hd:w-[18%] w-[20%] aspect-square bg-contain bg-no-repeat"
              style={{ backgroundImage: 'url(/images/discount.png)' }}
            />
            <div className="flex justify-between w-full">
              <div className="text-white">
                <div
                  className={clsx(
                    'font-Istok font-bold text-[18px] mr-2 flex flex-col',
                    isDiscount
                      ? 'lg:!text-[18px] !text-[13px] lg:max-w-[90%]'
                      : 'lg:text-[22px] text-base max-w-[70%]',
                  )}
                  dangerouslySetInnerHTML={{
                    __html: isDiscount
                      ? prices?.discount?.description
                        ? prices.discount.description.replaceAll('\n', '<br/>')
                        : ''
                      : 'Проверьте наличие скидки на ваш заказ',
                  }}
                ></div>
                <div className="lg:text-base text-sm font-normal mt-2 whitespace-pre-wrap max-w-3/4 lg:block hidden">
                  Срок действия скидки ограничен!
                </div>
              </div>
              {isDiscount ? (
                <div className="text-right lg:flex flex-col hidden">
                  <div className="text-[#B4B4B4] text-base font-normal whitespace-nowrap">
                    Итоговая сумма заказа
                  </div>
                  <div className="font-Istok text-[26px] text-white whitespace-nowrap">
                    {isDiscount
                      ? prices?.total && prices?.delivery && prices.total - prices.delivery > 0
                        ? prices.total - prices.discount.total
                        : 0
                      : prices?.total && prices?.delivery && prices.total - prices.delivery > 0
                      ? prices?.total
                      : 0}{' '}
                    ₽
                  </div>
                </div>
              ) : (
                <Button
                  variant="white"
                  className="font-Istok font-bold text-[18px] h-fit mt-2 lg:flex hidden"
                  onClick={() => dispatch(setDiscount(true))}
                >
                  Хочу&nbsp;скидку
                </Button>
              )}
            </div>
          </div>
          {isDiscount ? (
            <div className="lg:hidden flex flex-col mt-2">
              <div className="text-[#B4B4B4] lg:text-base text-[15px] font-normal whitespace-nowrap">
                Итоговая сумма заказа
              </div>
              <div className="font-Istok lg:text-[26px] text-[17px] text-white">
                {isDiscount
                  ? prices?.total && prices?.delivery && prices.total - prices.delivery > 0
                    ? prices.total - prices.discount.total
                    : 0
                  : prices?.total && prices?.delivery && prices.total - prices.delivery > 0
                  ? prices?.total
                  : 0}{' '}
                ₽
              </div>
            </div>
          ) : (
            <>
              <div className="lg:text-base text-sm font-normal mt-2 whitespace-pre-wrap max-w-3/4 lg:hidden block text-white">
                Срок действия скидки ограничен!
              </div>
              <Button
                variant="white"
                className="font-Istok font-bold text-[18px] h-fit mt-2 lg:hidden flex mt-4 lg:ml-auto"
                onClick={() => dispatch(setDiscount(true))}
              >
                Хочу&nbsp;скидку
              </Button>
            </>
          )}
        </div>

        <div style={{ borderTop: '1px solid #3E3E40' }} className="w-full h-[1px] mb-6" />
        <div className="flex lg:gap-4 gap-[11px] lg:w-fit w-full lg:ml-auto lg:flex-row flex-col">
          <Button
            variant="white"
            className="underline font-Istok lg:text-[18px] text-base lg:order-1 order-2 lg:mx-0 mx-auto disabled:opacity-50"
            onClick={(e) => {
              const top = e?.currentTarget?.getBoundingClientRect().top;
              if (top) {
                sessionStorage.setItem('modalTop', (top > 250 ? top - 250 : top).toString());
              }
              setIsModalOpen(1);
            }}
            disabled={prices.total - prices.delivery === 0}
          >
            Получить расчёт на email
          </Button>
          <Button
            variant="red"
            className="font-Istok lg:text-[18px] text-base lg:order-2 order-1 lg:mx-0 mx-auto lg:!w-fit !w-full"
            onClick={() => {
              dispatch(setShowOrder(true));
              scrollToTop();
            }}
            disabled={prices.total - prices.delivery === 0}
          >
            Оформить заказ
          </Button>
        </div>
      </div>

      {isModalOpen ? (
        <Modal className="w-[505px] relative" onClose={() => setIsModalOpen(0)} hideCloseButton>
          <button onClick={() => setIsModalOpen(0)} className="absolute right-6 top-6">
            <CloseIcon />
          </button>

          {isModalOpen === 1 ? (
            <>
              <h2 className="text-[30px] font-Istok font-bold">Расчёт на email</h2>
              <Formik
                initialValues={{ name: '', phone: '', email: '' }}
                validationSchema={validationSchema}
                onSubmit={(values) => {
                  handleSubmit(values);
                }}
              >
                {({ errors, touched, isValid }) => (
                  <Form className="flex flex-col gap-6 mt-6 w-full">
                    <FormItem name="name" placeholder="Имя" error={errors.name && touched.name} />
                    <Field name="phone">
                      {({
                        field, // { name, value, onChange, onBlur }
                        form, // also values, setXXXX, handleXXXX, dirty, isValid, status, etc.
                        meta,
                      }: any) => (
                        <div>
                          <Input
                            type="tel"
                            placeholder="Телефон +7 (XXX) XXX-XX-XX"
                            {...field}
                            {...form}
                            className={clsx(
                              'w-full font-normal',
                              meta.touched && meta.error && 'border-[#f00]',
                            )}
                          />
                          {meta.touched && meta.error && (
                            <ErrorMessage
                              name="phone"
                              component="div"
                              className="text-[#f00] pt-1 pl-4 font-Istok text-xs"
                            />
                          )}
                        </div>
                      )}
                    </Field>
                    <FormItem
                      name="email"
                      placeholder="E-mail"
                      error={errors.email && touched.email}
                    />

                    <span className="max-w-[90%]">
                      Нажимая на кнопку «Отправить» вы принимаете{' '}
                      <span
                        className="text-[#FF4545]"
                        role="button"
                        tabIndex={0}
                        onClick={() => {
                          window.open('https://www.mosokna.ru/upload/privacy-policy.pdf', '_blank');
                        }}
                      >
                        условия политики конфиденциальности в отношении обработки персональных
                        данных.
                      </span>
                    </span>
                    <Button variant="red" className="ml-auto" type="submit" disabled={!isValid}>
                      Отправить
                    </Button>
                  </Form>
                )}
              </Formik>
            </>
          ) : (
            <>
              <h2 className="text-[30px] font-Istok font-bold">Заявка отправлена</h2>
              <p className="text-[20px] font-normal mt-2 mb-6">
                Письмо с подробным расчётом придёт на ваш email в течение нескольких минут. Если
                письма нет —проверьте папку «Спам» или свяжитесь с нами по телефону
              </p>
              <Button variant="red" className="ml-auto" onClick={() => setIsModalOpen(0)}>
                Закрыть
              </Button>
            </>
          )}
        </Modal>
      ) : null}
    </>
  );
};
