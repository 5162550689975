import React, { useEffect, useState } from 'react';
import { Divider } from '../Divider';
import { nanoid } from 'nanoid';
import clsx from 'clsx';
import { useSelector } from 'react-redux';
import { RootState } from '../../redux/store';

export const Card = ({ data, options, item }: any) => {
  const prices = useSelector((state: RootState) => state.global.prices);
  const [play, setPlay] = useState(false);
  const [key, setKey] = useState(nanoid());

  useEffect(() => {
    if (play) {
      setTimeout(() => {
        setKey(nanoid());
        setPlay(false);
      }, 5000);
    }
  }, [play]);

  return (
    <div className="p-5 bg-white rounded-2xl flex lg:flex-row flex-col gap-5 w-full">
      <div
        className={clsx(
          'lg:w-[23%] w-1/3 aspect-square h-fit rounded-2xl bg-cover bg-no-repeat',
          key,
        )}
        style={{ backgroundImage: `url(${play ? `${item?.gif_path}?${key}` : item?.image_path})` }}
        onMouseEnter={() => !play && setPlay(true)}
      />
      <div className="flex flex-col flex-auto">
        <p className="font-Istok lg:text-base text-sm">{data?.name}</p>
        <div className="flex lg:flex-row flex-col w-full justify-between lg:text-[26px] text-[21px]">
          <p className="font-Istok">{item?.name || '?'}</p>
          <p className="font-Istok whitespace-nowrap">
            {item?.options?.install?.values?.[0]?.price
              ? prices?.rooms?.[data?.item_id]?.total - item.options.install?.values?.[0]?.price
              : prices?.rooms?.[data?.item_id]?.total}{' '}
            ₽
          </p>
        </div>
        <Divider className="my-3" />
        <div className="flex flex-col gap-[5px]">
          {options?.map((el: any) => {
            const item =
              el.selected !== undefined && el.selected !== -1 ? el?.values?.[el?.selected] : null;

            return item?.price && el.name !== 'Монтаж' ? (
              <div key={JSON.stringify(el)} className="flex w-full justify-between font-normal">
                <p className="lg:text-base text-sm">
                  {`${el.name}${
                    el?.name?.trim() !== item?.name?.trim() && item.name?.trim()
                      ? `: ${item.name}`
                      : ''
                  }`}
                </p>
                <p className="flex items-center lg:text-base text-sm whitespace-nowrap">
                  {item.price} ₽
                </p>
              </div>
            ) : null;
          })}
        </div>
      </div>
    </div>
  );
};
